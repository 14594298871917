.create {}

.create .form { max-width: 1000px; margin: 0 auto; }

/* .create .editor :global(.ProfileImage),
.create .editor :global(.system-form .form-field.form-field--image-upload .form-field--image-upload__preview) { }
.create .editor :global(.system-form .form-field.form-field--image-upload .form-field--image-upload__preview-file) {  }
.create .editor :global(.system-form .form-field.form-field--image-upload .form-field__field) { float: left; }
.create .editor :global(.system-form .form-field.form-field--display .form-field__field .display-image) { width: 275px; height: 275px; padding: 0; margin: 0; }
.create .editor :global(.system-form .form-field.form-field--display .form-field__field-container.ImageUpload) { width: auto; }
.create .editor :global(.system-form .form-field.form-field--display .form-field__field .display-field.display-image img) { float: left; } */