.filter { }
.filter :global(.system-form) { width: 80%; margin: 0 auto; }
.filter fieldset { display: flex; flex-direction: row; }
.filter :global(.system-form .system-form__form-section) { width: 60%; }
.filter :global(.system-form .system-form__actions) { width: 40%; margin-top: 0; border-bottom: none; }
.filter :global(.system-form .form-field .form-field__field-container) { width: 100%; }
.results { display: flex; flex-direction: row; flex-wrap: wrap; min-height: 300px; }
.results .noResults { font-size: 2rem; width: 100%; text-align: center; margin: 20px 0; color: #aaa }
.results .loader { background: rgba(255,255,255,0.95); width: 100%; }

:global(.step__section__partner.step__section--valid .system-form .sys-button) { background: #e9e9e9; border-color: transparent; color: #29313d } 
:global(.step__section__partner.step__section--valid .system-form .sys-button:focus) { border-color: #29313d; } 
:global(.step__section__partner.step__section--valid .system-form .sys-button .button-arrow) { border-color: #29313d; } 
:global(.step__section__partner .step-screen__actions) { margin-top: 0!important; margin-bottom: 20px; } 

.partnerSelector { display: flex; } 
.searchComponent { width: calc(100% - 360px); } 
.selection { display: flex; flex-direction: column; width: 300px; margin-left: 60px; align-items: flex-end; } 
.selection .selectionTitle { font-size: 1.6rem; font-weight: 500; margin-bottom: 10px; text-align: center; width: 100%; } 
:global(.step__section__partner.step__section--invalid) .selectionTitle { color: var(--error); } 
.selection .selectionInner { width: 300px; height: 108px; border: 2px solid var(--secondary-colour); border-radius: var(--radius); padding: 12px 20px 20px; display: flex; align-items: center; } 
.selection .selectionInner .taxonomyIcon { width: 50px; margin-right: 10px; display: flex;  }
.selection .selectionInner .taxonomyIcon > svg { width: 65%; }
.selection .selectionPill { color: #fff; margin-bottom: 8px; } 
.selection .selectionPill { background-color: var(--primary-colour); } 
.selection .noSelection { width: 220px; height: 108px; padding: 20px; color: #c2c2c2; border: 2px solid var(--secondary-colour); border-radius: var(--radius); } 
.selection .noSelection { display: flex; align-items: center; justify-content: center; } 
