.login { width: 100%; display: flex; align-items: center; justify-content: center; } 

.login .mainLoginForm { width: 40vw; padding: 30px 40px 50px 40px; top: -8vh; position: relative; } 

@media screen and (max-width: 1300px){
 .login .mainLoginForm { width: 60vw; } 
 }

 @media screen and (max-width: 800px){
 .login .mainLoginForm { width: 80vw; padding: 20px 30px; } 
 }

 @media screen and (max-width: 700px){
 .login .mainLoginForm :global(.system-form .form-field > label) { flex-direction: column; } 
 }