.calendar:global(.react-calendar) { width: 100%; border: none; border-radius: 5px; overflow: hidden; font-family: 'Montserrat', sans-serif; }
.calendar:global(.react-calendar .react-calendar__navigation) { background-color: var(--secondary-colour); margin-bottom: 0 }
.calendar:global(.react-calendar .react-calendar__navigation .react-calendar__navigation__label),
.calendar:global(.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow) { color: #fff }

.calendar:global(.react-calendar .react-calendar__navigation .react-calendar__navigation__label:disabled) { opacity: 1; }
.calendar:global(.react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover),
.calendar:global(.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:hover) { background-color: #222; }

.calendar:global(.react-calendar .react-calendar__tile) { border-radius: 5px; }
.calendar:global(.react-calendar .react-calendar__tile--now) { background-color: #f2f2f2; }
.calendar:global(.react-calendar .react-calendar__tile--active),
.calendar:global(.react-calendar .react-calendar__tile--active.react-calendar__month-view__days__day--weekend) { background-color: var(--primary-colour); }

.calendar:global(.react-calendar .react-calendar__month-view__days) { }
.calendar:global(.react-calendar .react-calendar__month-view__days__day),
.calendar:global(.react-calendar .react-calendar__month-view__days__day--weekend) { color: var(--secondary-colour) }
.calendar:global(.react-calendar .react-calendar__month-view__days__day--weekend) { background-color: #f4f4f4 ; }
.calendar:global(.react-calendar .react-calendar__month-view__days__day.react-calendar__tile--active) { color: #fff; }
.calendar:global(.react-calendar .react-calendar__month-view__days__day--neighboringMonth) { color: #aaa; }
.calendar:global(.react-calendar .react-calendar__month-view__weekdays__weekday) { border-bottom: 1px solid var(--secondary-colour); padding: 2px 0; }
.calendar:global(.react-calendar abbr[title]) { border: none; text-decoration: none; }

.calendar:global(.react-calendar .react-calendar__tile.react-calendar__tile--hasActive),
.calendar:global(.react-calendar .react-calendar__tile.react-calendar__tile--hasActive:enabled:hover),
.calendar:global(.react-calendar .react-calendar__tile.react-calendar__tile--hasActive:enabled:focus) { background-color: var(--primary-colour); color: #fff }
