/*
* global app styling
*/

.primary-bg { background: #2da35c; } 
.secondary-bg { background: #29313d; } 
.tertiary-bg { background: #c2c2c2; } 

:root { --primary-colour: #2da35c; --secondary-colour: #29313d; --tertiary-colour: #e2e2e2; --radius: 8px; --error: #f25757; --prestige: #ffe7aa; --warning: #003153 } 

/* status colours */
.completed { background: #2da35c; color: #fff; }
.alert { background: #f25757; color: #fff; }
.pending { background: #29313d; color: #fff; }
.cancelled { background: #000; color: #fff; }
.stale { background: #ccc; color: #29313d }

html,
body { min-height: 100%; height: 100%; background-color: #f9f9f9; height: -webkit-fill-available; min-height: -webkit-fill-available; padding: 0; margin: 0; font-family: 'Montserrat', sans-serif; line-height: 1.6; font-size: 16px; color: #29313d } 

html,
body,
#root,
#root > .screen { height: 100%; width: 100%; display: flex; flex-direction: row; } 
#root { height: calc(100% - 16px); margin: 8px; border: 1px solid #f2f2f2; border-radius: var(--radius); } 
#root > .react-reveal,
#root > .screen > .react-reveal { width: 100%; } 
#root > .screen > .react-reveal { display: flex; flex-direction: row; } 

#root > .screen > .display,
#root > .screen > .react-reveal > .display { width: 100%; height: 100%; } 
#main { min-height: calc(100% - 110px);  } /* margin-right: 20px;  */

.panel { background: #fff; border: 1px solid #f9f9f9; -webkit-box-shadow: 0px 0px 6px -1px rgba(226,226,226,1); -moz-box-shadow: 0px 0px 6px -1px rgba(226,226,226,1); box-shadow: 0px 0px 6px -1px rgba(226,226,226,1); border-radius: var(--radius); } 
.panel-strong { background: #fff; border: 1px solid #f9f9f9; -webkit-box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); -moz-box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); border-radius: var(--radius); } 

h1 { margin: 10px 0; } 

hr.sep { width: 100%; } 

.role--super { background:#fcffa6; color:#29313d } 
.role--admin { background-color: #29313d; color: #fff; } 
.role--user { background-color: #70b883; color:#fff } 
.role--member { background-color: #b5deff; color:#29313d } 
.role--partner { background-color: #cab8ff; color:#29313d } 

/* global button effects */
.btn.btn--danger { background-color: var(--error); color: #fff; } 
.btn.btn--danger .button-arrow { border-color: #fff; } 


/* label tags */
.label-tag { border-radius: 10px; padding: 6px 13px; background-color: #f2f2f2; color: #777; transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s; } 

.screen-actions { width: 100%; float: left; margin-top: 20px; display: flex; justify-content: flex-start; } 
.screen-actions:first-child { margin-top: 0; } 
.screen-actions .btn { margin-left: 20px; } 
.screen-actions .btn:first-child { margin-left: 0; } 

.detail-label { border-radius: 25px; background-color: #424242; padding: 12px 21px; } 

/* global avatar */
.avatar,
.avatar img { border-radius: 3%; } 

/* base layout */
.layout__sub-section { display: flex; max-width: 1400px; margin: 0 auto; } 
.layout__side-panel { width: calc(30% - 20px); margin-right: 20px; } 
.layout__editor-panel { width: 70%; padding: 20px 26px; } 

/* application global animation effects (ReactTransitionGroup <CSSTransition /> Component) */
.animate-fadeinout { transition: opacity 0.1s ease-in-out; opacity: 0; } 
.animate-fadeinout-enter-active { opacity: 0; } 
.animate-fadeinout-enter-done { opacity: 1; } 
.animate-fadeinout-exit-active { opacity: 0; } 
.animate-fadeinout-exit-done { opacity: 0; } 
.animate-fadeout { transition: opacity 0.2s ease-in-out; } 
.animate-fadeout-enter-active { opacity: 0; } 
.animate-fadeout-enter-done { opacity: 1; } 

/* .animate-fadeout-exit { opacity: 1 } */
.animate-fadeout-exit-active { opacity: 0; } 
.animate-fadeout-exit-done { opacity: 0; } 

.slew-up-enter { transform: translateY(50px); -webkit-transform: translateY(50px); opacity: 0; } 
.slew-up-enter-active,
.slew-up-enter-done { transform: translateY(0); -webkit-transform: translateY(0); opacity: 1; transition: all ease-in-out 200ms; } 
.slew-up-exit { transform: translateY(0); -webkit-transform: translateY(0); opacity: 1; } 
.slew-up-exit-active { transform: translateY(-50px); -webkit-transform: translateY(-50px); opacity: 0; transition: all ease-in-out 200ms; } 
.slew-left-enter { transform: translateX(100px); -webkit-transform: translateX(100px); opacity: 0; } 
.slew-left-enter-active { transform: translateX(0); -webkit-transform: translateX(0); opacity: 1; transition: all ease-in-out 300ms; } 
.slew-left-exit { transform: translateX(0); -webkit-transform: translateX(0); opacity: 1; } 
.slew-left-exit-active { transform: translateX(-100px); -webkit-transform: translateX(-100px); opacity: 0; transition: all ease-in-out 300ms; } 

/* overrides for toastify notifications */
.Toastify__toast-container--top-right { top: 17px; right: 26px; } 
.Toastify__toast { border-radius: 5px; min-height: 0; padding: 0; -webkit-box-shadow: 0px 0px 5px 0px rgba(110,110,110,0.32); -moz-box-shadow: 0px 0px 5px 0px rgba(110,110,110,0.32); box-shadow: 0px 0px 6px 3px rgba(210,210,210,0.30); } 
.Toastify__toast .Toastify__toast-body { padding: 0; margin: 0; flex: none; display: block; width: 100%; } 
.Toastify__toast-body > div:last-child { flex: none } 

/* modals */
.ReactModal__Overlay { opacity: 0; transition: opacity ease-in-out 0.2s; } 
.ReactModal__Overlay--after-open { opacity: 1; } 
.ReactModal__Overlay--before-close { opacity: 0; } 

.sys-modal { position: fixed; left: 0; top: 0; width: 100vw; height: 100vh; background-color: rgba(41, 49, 61, 0.9); } 
.sys-modal .modal-body { display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; padding: 0 40px; } 

/* dialogs */
.dialog-notification { margin: 0; padding: 20px 0; padding-right: 20px; background-color: #e2e2e2; border-radius: 5px; } 
.dialog-notification .notification-label { font-size: 1.6rem; text-align: left; } 
.dialog-notification .notification-decoration { border-top-right-radius: 0; border-bottom-right-radius: 0; } 

/* application keyframe animations */
@keyframes rotate { 
 from { transform: rotate(0deg); } 

 to { transform: rotate(359deg); } 
 }

@keyframes arrowBounceSubtleDown { 0%,
 100% { transform: translateY(-1px); } 

 50% { transform: translateY(-2px); } 
 }

@keyframes arrowBounceSubtleUp { 0%,
 100% { transform: translateY(0); } 

 50% { transform: translateY(1px); } 
 }

 /* @keyframes bouceUpSubtle { 0%,
 100% { transform: translateY(0); } 
 
 50% { transform: translateY(-40px); } 
 }*/