
.loginForm :global(.system-form .form-field .form-field__label) { width: 35%; padding-right: 8%; text-align: right; } 
.loginForm :global(.system-form .form-field .form-field__field-container) { width: 60%; } 

.loginForm { } 

.formHeader { display: flex; align-items: center; } 
.formHeader .logo { width: 60px; height: 90px; } 
.formHeader .titles { display: flex; flex-direction: column; width: calc(100% - 80px); text-align: left; margin-left: 20px; position: relative; } 
.formHeader .titles .loginFormTitle { font-size: 2.8rem; line-height: 3.8rem; font-weight: 700; margin: 0; } 
.formHeader .subTitle { font-variant: small-caps; margin-left: 2px; } 
.sep { margin-bottom: 50px; background: #e2e2e2; border: none; height: 1px; } 

.loginForm :global(.system-form .system-form__actions) { text-align: right; margin-top: 30px; justify-content: flex-end; } 

.loginForm .forgottenPassword { width: 100%; margin-top: 20px; } 
.loginForm .forgottenPassword > div { cursor: pointer; float: right; font-size: 1.7rem; text-align: right; } 
.loginForm .forgottenPassword > div:hover,
.loginForm .forgottenPassword > div:focus { text-decoration: underline; } 

@media screen and (max-width: 700px){
 .loginForm :global(.system-form) { max-width: 90%; margin: 0 auto; } 
 .sep { margin-bottom: 20px; } 
 .loginForm :global(.system-form .form-field .form-field__label),
 .loginForm :global(.system-form .form-field .form-field__field-container) { text-align: left; width: 100%;; } 
 .loginForm :global(.system-form .form-field .form-field__label) { margin-bottom: 8px; padding-left: 22px; } 
 }