.info { display: flex;  } 
.profile {  margin-right: 20px;  } 
.profile .taxonomyIcon { width: 100%; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; }
.profile .taxonomyIcon svg { width: 70%; }
.info .profileSummaryDetails { margin-top: 10px; width: calc(100% - 70px); } 
.name { font-size: 2rem; } 
.partnerId { font-size: 1.4rem; color: #222; font-weight: 600; display: flex; align-items: center; }
.partnerIdValue {  }
.accountSince { font-size: 1.4rem; }

.prestigePill { background-color: var(--prestige); }
.pill { margin-left: 8px; margin-top: 1px; color: #fff; font-weight: 400; }
.pill.publishedPill { background-color: var(--primary-colour); }
.pill.unpublishedPill { background-color: var(--error); }

.tags { display: flex; flex-wrap: wrap; }
.tags .tag { margin-left: 10px; padding: 4px 8px; font-size: 1.2rem; margin-top: 10px; margin-bottom:5px; }
.tags .tag:first-child { margin-left: 0; }

.loader { width: 80%; margin: 0 auto; top: 20%; position: relative; }
.loader :global(.custom-loader__roundel > div) { width: 40px; height: 40px; margin-top: 16px; }
.loader :global(.custom-loader__body .custom-loader__message) { font-size: 1.6rem; margin-top: 10px; margin-bottom: 0; }