.partner { width: 100%; display: flex; flex-direction: row; align-items: center; }
.partner .heading { display: flex; flex-direction: column; width: 100%; align-items: left; color: var(--secondary-colour) }
.partner .heading .location { display: flex; flex-direction: column; }
.partner .heading .location .territory {  }
.partner .name { font-weight: 500; font-size: 1.8rem; line-height: 2.8rem; margin-right: 20px; }
.partner .contactDetails { display: flex; justify-content: flex-end; }
.partner .contactDetails .telephone {  }
.partner .contactDetails .telephone a { text-decoration: none; cursor: pointer; transition: all 0.1s ease-in-out; font-weight: 500; display: flex; align-items: center; justify-content: center; color: var(--secondary-colour); padding: 11px 20px 10px 20px; border-radius: var(--radius); border: 2px solid var(--secondary-colour) }
.partner .contactDetails .telephone a .telIcon svg { width: 18px; height: 18px; margin-right: 12px; top: 3px; position: relative; }
.partner .contactDetails .telephone a .telIcon svg g path { fill: var(--secondary-colour); transition: all 0.1s ease-in-out }
.partner .contactDetails .telephone a:hover { color: #fff; background-color: var(--secondary-colour); }
.partner .contactDetails .telephone a:hover .telIcon svg g path { fill: #fff!important; }

