.appNav { width: 30%; height: 100%; margin-right: 5px; max-width: 130px; float: left; transition: width ease-in 300ms; } 

.appNavExplorer > div { height: 100%; } 



.appNav .navigationItem { width: 100%; padding: 5px 20px; padding-top: 8px; margin: 20px 0; border-left: 5px solid transparent; border-radius: 5px; } 
.appNav .navigationItemLink { display: flex; flex-direction: column; align-items: center; justify-content: center; text-decoration: none; } 
.appNav .navigationItem svg { width: 24px; height: 24px; transition: ease-in-out fill 0.2s; } 
.appNav .navigationItem .label { font-size: 1.4rem; font-weight: 500; color: #888888; margin-top: 7px; transition: ease-in-out color 0.2s; } 

.appNav .navigationItem:not(.navigationItemActive):hover svg { fill:var(--secondary-colour); } 
.appNav .navigationItem:not(.navigationItemActive):hover .label { color: #444; } 
.appNav .navigationItemActive { border-left-color: var(--primary-colour); } 
.appNav .navigationItemActive svg { fill:var(--primary-colour); } 
.appNav .navigationItemActive .label { font-weight: 700; color: var(--secondary-colour) } 

.appNavExplorer { height: calc(100% - 90px); padding-top: 30px; } 

.appNavMode { transition: opacity var(--animation-duration) ease-in-out; opacity: 0 } 

.appNavMode-enter-active,
.appNavMode-enter-done { opacity: 1; } 

@media only screen and (max-width: 800px){
 .appNav .asideHomeLink { display: none; } 
 .appNav { width: 0; } 
 .appNavExplorer { padding-top: 0; z-index: 2; position: fixed; left: 0; bottom: 0; background: #fff; width: 100%; height: 80px; box-shadow: 0px 4px 16px 4px rgba(200,200,200,0.7) } 
 .appNavExplorer ul { display: flex; } 
 .appNavExplorer ul > li.navigationItem { margin-top: 0; padding-top: 13px; border-left: 0; border-top: 5px solid transparent; } 
 .appNavExplorer ul > li.navigationItemActive { border-top-color: var(--primary-colour); } 
 }