.userListingResult { display: flex; flex-direction: row; padding: 6px 0; position: relative; } 
.userListingResult .profileContainer { width: 70px; margin-right: 15px; } 
.userListingResult .profileContainer .taxonomyIcon { width: 100%; } 
.userListingResult .profileContainer .taxonomyIcon svg { width: 48%; height: auto; margin: 0 auto; display: block; margin-bottom: 4px; margin-top: 7px; } 
.userListingResult .profileContainer .activeDisplay { text-align: center; } 
.userListingResult .profileContainer .activeDisplay .active,
.userListingResult .profileContainer .activeDisplay .expired { padding: 3px 6px; margin: 0 auto; font-size: 1rem; color: #fff; } 
.userListingResult .profileContainer .activeDisplay .active { background-color: var(--primary-colour); } 
.userListingResult .profileContainer .activeDisplay .expired { background-color: var(--secondary-colour); } 
.userListingResult .profileContainer .activeDisplay .awaitingActivation { background-color: var(--warning); color: #fff; }
.userListingResult .profileContainer .activeDisplay .locked { background-color: var(--error); }
.userListingResult .profileContainer .activeDisplay .expired :global(.date-year) { font-weight: 500; } 
.userListingResult .information { width: calc(100% - 213px); } 
.userListingResult .information .upper { display: flex; align-items: center; } 
.userListingResult .information .headline { display: flex; flex-direction: column; width: 80%; font-size: 1.6rem; } 
.userListingResult .information .headline .number { font-size: 1.8rem; font-weight: 500; } 
.userListingResult .information .headline .name { display: flex; } 
.userListingResult .information .headline .name .email { margin-left: 30px; font-size: 1.4rem; color:#999 } 
.userListingResult .information .tags { width: 100%; margin-top: 15px; display: flex; align-items: center; flex-direction: row; color: #888; } 
.userListingResult .information .tags .tag { margin-left: 20px; font-size: 1.4rem; display: flex; align-items: center; } 
.userListingResult .information .tags .tag .label { margin-right: 10px; } 
.userListingResult .information .tags .tag .value { font-weight: 500; } 
.userListingResult .information .tags .tag:first-child { margin-left: 0; } 
.userListingResult .type { display: flex; align-items: center; width: 128px; justify-content: center; } 
.userListingResult .typeTag { background-color: var(--secondary-colour); width: 128px; text-align: center; color: #fff; font-size: 1.4rem; } 
.userListingResult .actions { position: absolute; right: 0; top: 6px; } 

@media screen and (max-width: 1600px){
 .userListingResult .information .headline .name { flex-direction: column; } 
 .userListingResult .information .headline .name > div { width: 100%; } 
 .userListingResult .information .headline .name .email { margin-left: 0 } 
 }

@media screen and (max-width: 1400px){
 .userListingResult .information { width: 100%; } 
 }

 @media screen and (max-width: 960px){
 .userListingResult .information .tags .tag { flex-direction: column; } 
 }
