.membershipHeadline .membershipSummary { max-width: 800px; margin: 20px auto 0; padding: 30px 34px; background: var(--secondary-colour); border-radius: var(--radius); } 
.membershipHeadline .membershipSummary .field { display: flex; align-items: center; } 
.membershipHeadline .membershipSummary .field .label { margin-right: 10px;  color: #e2e2e2 } 
.membershipHeadline .membershipSummary .field .value { padding: 12px; font-weight: 400; background: rgba(255,255,255,0.1); color: #fff; border-radius: var(--radius); } 

.notAvailable { color: #fff; display: flex; align-items: center; }

.membershipHeadline .membershipSummary .stats { display: flex; align-items: center; justify-content: space-around; border-bottom: 2px solid #fff; margin-bottom: 24px; padding-bottom: 16px; } 
.membershipHeadline .membershipSummary .stats .field { flex-direction: column; width: 23%; text-align: center; margin-top: 0; } 
.membershipHeadline .membershipSummary .stats .field .value { background: #f2f2f2; font-weight: 600; color: var(--secondary-colour); margin-bottom: 10px; font-size: 1.8rem; } 
.membershipHeadline .membershipSummary .stats .field .value.balance { background: #f2f2f2; font-weight: 600;  margin-bottom: 10px; font-size: 1.8rem; } 
.membershipHeadline .membershipSummary .stats .field .value.balance.outstanding { color: var(--error); }
.membershipHeadline .membershipSummary .stats .field .label { margin-right: 0; width: 100%; font-weight: 500; } 
