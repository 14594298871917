.expandToggle { cursor: pointer;  display: flex; align-items: center; justify-content: flex-end; align-items: center; } 

.expandToggle .label { margin-right: 8px; color: #aaa; font-size: 1.2rem; }
.expandToggle .icon {  }

 .closed .vertical { transition: all 0.5s ease-in-out; transform: rotate(-90deg); } 
 .closed .horizontal { transition: all 0.5s ease-in-out; transform: rotate(-90deg); opacity: 1; } 
 
 .opened { opacity: 1; } 
 .opened .vertical { transition: all 0.5s ease-in-out; transform: rotate(90deg); } 
 .opened .horizontal { transition: all 0.5s ease-in-out; transform: rotate(90deg); opacity: 0; } 
 
 .circlePlus { height: 24px; width: 24px; opacity: 0.7; position: relative; } 
 .circlePlus .circle { position: relative; width: 24px; height: 24px;  } 
 .circlePlus .circle .horizontal,
 .circlePlus .circle .vertical { border-radius: 2px; }
 .circlePlus .circle .horizontal { position: absolute; background-color: #aaa; width: 16px; height: 3px; left: 50%; margin-left: -8px; top: 50%; margin-top: -1.5px; } 
 .circlePlus .circle .vertical { position: absolute; background-color: #aaa; width: 3px; height: 16px; left: 50%; margin-left: -1.6px; top: 50%; margin-top: -8px; } 