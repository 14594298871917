.adjustCreditBalance { } 
.loader { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.8); border-radius: var(--radius); } 

.dialog { padding: 25px 35px; width: 800px; min-height: 120px; top: -6vh; position: relative; } 
.dialog :global(.system-form .form-field .form-field__label) { width: 30%; } 
.dialog :global(.system-form .form-field .form-field__field-container) { width: 65%; } 
.dialog :global(.system-form .form-field) { margin-top: 0 } 
.dialog :global(.system-form .system-form__actions) { display: none; } 
.dialog .actions { border-top: 1px solid #e2e2e2; padding-top: 24px; display: flex; justify-content: space-between } 

@media screen and (max-width: 1200px){
 .dialog { width: 500px; } 
 .dialog :global(.system-form .form-field .form-field__label),
 .dialog :global(.system-form .form-field .form-field__field-container) { width: 100%; } 
 }

@media screen and (max-width: 600px){
 .dialog .actions { flex-direction: column; } 
 .dialog .actions>button { margin-top: 20px; padding: 12px 0px; } 
 .dialog .actions>button:first-child { margin-top: 0; } 
 }