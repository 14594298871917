.appHeader { margin-right: 20px; height: 90px; display: flex; transition: width linear 450ms; justify-content: space-between; align-items: center; } 
.appHeader .hgroup { display: flex; flex-direction: row; align-items: center; } 
.appHeader .homeIconLink { display: none; margin-right: 20px; } 
.appHeader .mainTitle { font-size: 2rem; line-height: 3rem; } 
.appHeader .mainTitle .beta { color: var(--primary-colour); font-weight: 300; font-style: italic; } 

.appHeader .account-dialog { float: right; position: relative; } 

.appHeader .offline-mode-indicator { float: right; width: 90px; background: #acacac; height: 100%; position: relative; display: table; padding: 0 10px; } 
.appHeader .offline-mode-indicator__icon { display: table-cell; vertical-align: middle; width: 24px; padding-right: 5px; padding-top: 2px; } 
.appHeader .offline-mode-indicator__label { display: table-cell; vertical-align: middle; } 
.appHeader .offline-mode-indicator { margin-right: -90px; transition: margin-right 0.25s ease-in-out; } 
.appHeader .offline-mode-indicator.animate-slide-in-enter-active { margin-right: 0; } 
.appHeader .offline-mode-indicator.animate-slide-in-enter-done { margin-right: 0; } 
.appHeader .offline-mode-indicator.animate-slide-in-exit { margin-right: -90px; } 
.appHeader .offline-mode-indicator.animate-slide-in-exit-done { margin-right: -90px; } 

/* .animate-fadeinout-enter-active { opacity: 0; } 
.animate-fadeinout-enter-done { opacity: 1; } 
.animate-fadeinout-exit-active { opacity: 0; } 
.animate-fadeinout-exit-done { opacity: 0; } */

.appHeader .account-dialog .account-dialog__control { opacity: 0.5; transition: opacity ease-in-out 0.2s, background-color ease-in-out 0.2s; } 
.appHeader .account-dialog .account-dialog__control:hover { opacity: 0.7; } 
.appHeader .account-dialog .account-dialog__control svg.profile-icon { width: 24px; height: 24px; left: 18px; top: 1px; } 
.appHeader .account-dialog.dialog-active .account-dialog__control { background: rgba(255,255,255,0.2); opacity: 0.7; } 
.appHeader .account-dialog.dialog-active .account-dialog__control:hover { background: rgba(255,255,255,0.25); } 
.authenticated .appHeader .account-dialog .account-dialog__control { opacity: 1; } 

/* .user-authentication-control__roundel svg { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); } 
.user-authentication-control__roundel .circle { stroke-dasharray: 1000; stroke-dashoffset: 1000; animation: stroke 7s ease-out forwards; } */
 
 @keyframes stroke { to { stroke-dashoffset: 0; } 
 }

 @media only screen and (max-width: 800px){
 .appHeader .homeIconLink { display: flex; width: auto; } 
 }