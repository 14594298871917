.panel { padding: 20px 24px; max-width: 800px; margin: 0 auto; } 
.panel h2 { color: #888; font-size: 2rem; font-weight: 500; } 
.loader { width: 80%; margin: 0 auto; } 
.loader :global(.custom-loader__roundel > div) { width: 40px; height: 40px; } 
.loader :global(.custom-loader__body .custom-loader__message) { font-size: 1.6rem; margin-top: 20px } 

.heading { display: flex; justify-content: space-between; align-items: center; } 

.payments { list-style: none; padding: 0; } 
.payments .payment { margin-top: 10px; } 
.payments .payment a { display: block; padding: 20px 14px; background: #f9f9f9; border-radius: var(--radius); transition: background ease-in-out 0.2s, color ease-in-out 0.2s; cursor: pointer; text-decoration: none; color: var(--secondary-colour) } 
.payments .payment.overdue a { background-color: var(--error); color: #fff } 
.payments .payment.overdue a .statusPill { background: #fff; color: var(--error); font-weight: 500; } 
.payments .payment:hover a { background: #f2f2f2; } 
.payments .payment.overdue:hover a { background: var(--secondary-colour); } 
.payments .payment.active a { background: var(--primary-colour); color: var(--secondary-colour) } 
.payments .payment.active a :global(.sys-checkmark:after) { border-color: #fff; } 
.payments .payment.active a { color: #fff } 

.statusPill { width: 100%; } 
.overdueWarning { height: 26px; margin-top: -10px; position: relative; }
.overdueWarning svg { width: 24px; height: 24px; }

.payments .paymentEntity { display: flex; align-items: center; } 
.payments .paymentEntity.cancelled div.amount,
.payments .paymentEntity.cancelled div.referenceId,
.payments .paymentEntity.cancelled div.reason { opacity: 0.4 } 
.payments .paymentEntity .info {width: calc(90% - 30px); margin-left: 10px; display: flex;flex-direction: column; }
.payments .paymentEntity .details { display: flex; }
.payments .paymentEntity .details > div { text-align: left; }
.payments .paymentEntity div.dueDate { width: 27.5%; min-width: 85px; font-weight: 500; } 
.payments .paymentEntity div.credits { width: 10%; text-align: center;  }
.payments .paymentEntity div.referenceId { width: 42.5%; min-width: 100px; } 
.payments .paymentEntity div.amount { width: 10%; min-width: 80px; text-align: right; font-weight: 600; } 
.payments .paymentEntity div.reason { width: 100%; min-width: 200px; font-size: 1.4rem; margin-top: 5px; } 
.payments .paymentEntity .taxonomyStatus { width: 10%; min-width: 64px; text-align: center; display: flex; flex-direction: column; align-items: center; } 
.payments .paymentEntity .taxonomyIcon { width: 100%; } 
.payments .paymentEntity .taxonomyIcon svg { width: 30px; height: 30px; fill: var(--secondary-colour) } 
.payments .payment:first-child { margin-top: 0; } 
.payments .payment .paymentEntity { display: flex; justify-content: space-between; } 
.payments .payment .arrow { opacity: 0; transition: opacity 0.2s ease-in-out; width: 10px; } 
.payments .payment:hover .arrow { opacity: 1; } 