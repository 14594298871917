
.steps { width: 100%; } 
.steps .step { } 
.steps .step.valid { } 
.steps .step.active { } 
.steps .step.invalid { } 

.steps .step .actions { margin-top: 40px; display: flex; justify-content: flex-end; } 
.steps .step.valid .actions button { background: var(--primary-colour); color: #fff } 
.steps .step.valid .actions button :global(.button-arrow) { border-color: #fff; } 

.controls { display: flex; justify-content: space-evenly; max-width: 80%; margin: 30px auto 0; padding: 20px 0; } 
.controls .control { margin: 0 30px; position: relative; padding-bottom: 8px; cursor: pointer; font-weight: 500; display: flex; flex-direction: column; align-items: center; } 
.controls .controlDisabled { cursor: default; opacity: 0.3 } 
.controls .control span.controlStateFx { position: absolute; bottom: 0; width: 0; left: 0; height: 2px; background-color: #333; transition: ease-in-out width 0.15s; } 
.controls .control .labelContainer { position: relative; display: flex; } 
.controls .control .labelContainer .numberIncrement { margin-right: 6px; } 
.controls .control .labelContainer .controlLabel { position: relative; padding-bottom: 3px; font-weight: 500; } 
.controls .control .controlArrow { border: solid #29313d; border-width: 0 3px 3px 0; position: absolute; right: -30px; top: 11px; margin-top: -4px; padding: 3px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); transition: border-color ease-in-out 0.2s } 
.controls .control:hover:not(.controlActive, .controlDisabled) span.controlStateFx { width: 100%; } 
.controls .control.controlActive { color: var(--secondary-colour); } 
.controls .control.controlActive span.controlStateFx { width: 100%; background-color: var(--secondary-colour); } 

.controls .control.controlValid { color: var(--primary-colour); } 
.controls .control.controlValid span.controlStateFx { background-color: var(--primary-colour); } 
.controls .control.controlInvalid { color: var(--error); } 
.controls .control.controlInvalid span.controlStateFx { background-color: var(--error); } 

.controls .indicator { width: 28px; height: 42px; margin-bottom: 4px; text-align: center; } 
.controls .indicator .numberIncrement { top: 0.5px; font-size: 1.6rem; position: relative; } 
.controls .indicator .outline { width: 28px; height: 28px; border-radius: 50%; border: 2px solid #29313d; position: relative; } 
.controls .indicator .sep { width: 2px; height: 6px; left: 13px; top: 3px; background: #29313d; position: relative; } 

