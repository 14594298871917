

.customLoader { display: flex; z-index: 1; opacity: 1; -webkit-transition: opacity 0.2s ease-in-out; -moz-transition: opacity 0.2s ease-in-out; transition: opacity 0.2s ease-in-out; align-content: center; flex-direction: column; justify-content: center; } 
.customLoader .header { display: flex; align-items: center; justify-content: center; } 
.customLoader .status { position: relative; } 
.customLoader .body { text-align: center; } 
.customLoader .body .message { text-align: center; font-size: 2rem; line-height: 3rem; margin-bottom: 30px; margin-top: 40px; } 

.customLoader .loaderContainer { display: inline-block; } 

.customLoader .header.customLoaderFailed { animation: shimmy 0.72s cubic-bezier(.36,.07,.19,.47) both; } 

.circleLoader { border: 3px solid rgba(0, 0, 0, 0.2); border-left-color: #5cb85c; animation: loader-spin 0.5s infinite linear; position: relative; border-radius: 50%; width: 50px; height: 50px; } 
.circleLoader.failed { border-color: #ff6961 } 
 
 .loadComplete { -webkit-animation: none; animation: none; border-color: #5cb85c; transition: border 500ms ease-out; } 
 
 /* .checkmark { display: none; } */
 .checkmarkDraw:after { animation-duration: 800ms; animation-timing-function: ease; animation-name: checkmark; transform: scaleX(-1) rotate(135deg); } 
 .checkmark:after { opacity: 1; height: 20px; width: 7px; transform-origin: left top; border-right: 3px solid #5cb85c; border-top: 3px solid #2da35c; content: ""; left: 11px; top: 24px; position: absolute; } 

.crossmark:after,
.crossmark:before { content: ''; height: 48%; border-left: 3px solid #ff6961; position: absolute; left: 46%; top: 26%; transition: height ease-in-out 0.2s; } 
.crossmark:after { transform: rotate(45deg); } 
.crossmark:before { transform: rotate(-45deg); } 
 
 @keyframes loader-spin { 
 0% { transform: rotate(0deg); } 
 100% { transform: rotate(360deg); } 
 }
 @keyframes checkmark { 
 0% { height: 0; width: 0; opacity: 1; } 
 20% { height: 0; width: 7px; opacity: 1; } 
 40% { height: 20px; width: 7px; opacity: 1; } 
 100% { height: 20px; width: 7px; opacity: 1; } 
 }

 
@keyframes shimmy { 10%, 90% { transform: translate3d(-0px, 0, 0); } 
 
 20%, 80% { transform: translate3d(1px, 0, 0); } 
 
 30%, 50%, 70% { transform: translate3d(-2px, 0, 0); } 
 
 40%, 60% { transform: translate3d(2px, 0, 0); } 
 }


 @media (orientation: portrait){
 .customLoader { position: relative; top: -5vh; } 
 }
