.item { margin-top: 20px; width: 31%; margin-left: 2%; height: 180px; } 
.item > .inner { border: 3px solid transparent; border-radius: var(--radius); padding: 20px 24px; display: flex; flex-direction: column; } 
.item.selected > .inner { border-color: var(--primary-colour); } 
.item .profile { display: flex; flex-direction: row; align-items: center; } 
.item .profile > .roundel { min-width: 75px; width: 75px; height: 75px; } 
.item .profile > .summary { margin-left: 20px; } 
.item .profile > .summary .name { font-size: 1.8rem; font-weight: 500; } 
.item .email { margin-top: 6px; } 
.item .select { display: flex; justify-content: flex-end; margin-top: 16px; } 

@media screen and (max-width: 1300px) {
    .item { width: 48%; } 
 }