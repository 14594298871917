.panel { }
.panel h2 { color: #888; font-size: 2rem; font-weight: 500; }
.loader { width: 80%; margin: 0 auto; }
.loader :global(.custom-loader__roundel > div) { width: 40px; height: 40px; }
.loader :global(.custom-loader__body .custom-loader__message) { font-size: 1.6rem; margin-top: 20px }

.heading { display: flex; justify-content: space-between; align-items: left; } 
.panel .new { margin-top: 20px; padding-top: 20px; border-top: 1px solid #888; } 

.memberships { list-style: none; padding: 0; }
.memberships .membership { margin-top: 10px; }
.memberships .membership a { display: block; padding: 10px; background: #f9f9f9; border-radius: var(--radius); transition: all ease-in-out 0.2s; cursor: pointer; text-decoration: none; color: var(--secondary-colour) }
.memberships .membership:hover a { background: #f2f2f2; }
.memberships .membership.active a { background: var(--primary-colour) }
.memberships .membership.active a :global(.sys-checkmark:after) { border-color: #fff; }
.memberships .membership.active a { color: #fff }
.memberships .membership.active .taxonomyIcon svg { fill: #fff; transition: fill ease-in-out 0.2s; }


.memberships .membership:first-child { margin-top: 0; }
.memberships .membership .membershipEntity { display: flex; justify-content: space-around; }
.memberships .membership .membershipNumber { display: flex; align-items: center; padding-top: 1.5px; }
.memberships .membership .taxonomyIcon { width: 22px; margin-right: 10px; display: flex; align-items: center; }
.memberships .membership .taxonomyIcon svg { width: 100%; height: auto; fill: #888 }
.memberships .membership .status { position: relative; width: 40px; margin-right: 10px; }
.memberships .membership .status .crossmark:before,
.memberships .membership .status .crossmark:after { border-color: #888; border-width: 3px; }
.memberships .membership .arrow { opacity: 0; transition: opacity 0.2s ease-in-out; width: 10px; }
.memberships .membership:hover .arrow { opacity: 1; }