.splash { border-radius: var(--radius); width: 100; height: 100%; top: 0; left: 0; background: var(--primary-colour); position: relative; display: flex; align-items: center; justify-content: center; } 

.splashInner { position: relative; top: -10vh; } 

.splash .header { display: flex;  border-bottom: 2px solid #fff; } 
.splash .header .logo { width: 120px; margin-right: 20px; } 

.splash .header .titles { display: flex; flex-direction: column; justify-content: center; color: #fff; padding-right: 30px; } 
.splash .header .title { font-size: 4rem; font-weight: 700; } 
.splash .header .subTitle { font-size: 2rem; margin-left: 2px; font-variant: small-caps; } 

.splash .content { } 

.splash .loading { margin-top: 80px; position: relative; display: flex; flex-direction: column; }
.splash .loading .loadAnim { left: 5px; display: block; margin: 0 auto; width: 80px; height: 80px; position: relative; } 
.splash .loading .loadAnim div { position: absolute; border: 4px solid #fff; opacity: 1; border-radius: 50%; animation: rays 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; } 
.splash .loading .loadAnim div:nth-child(2) { animation-delay: -0.5s; } 
.splash .loading .loadingLabel { text-align: center; color: #fff; opacity: 0.8; margin-top: 10px; }

@keyframes rays { 
 0% { top: 36px; left: 36px; width: 0; height: 0; opacity: 1; } 
 100% { top: 0px; left: 0px; width: 72px; height: 72px; opacity: 0; } 
 }