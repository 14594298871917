.partnerListingResult { display: flex; flex-direction: row; align-items: center; } 
.partnerListingResult .information { width: 70%; } 
.partnerListingResult .headline { width: 100%; display: flex; flex-direction: row; } 
.partnerListingResult .headline .taxonomyIcon { width: 40px; display: flex; margin-right: 15px; } 
.partnerListingResult .headline .taxonomyIcon svg { width: 24px; margin: 0 auto; display: block; fill: #888; } 
.partnerListingResult.prestige .headline .taxonomyIcon svg { fill: #eec64f; } 
.partnerListingResult.unpublished .taxonomyIcon svg { opacity: 0.2; } 
.partnerListingResult .headline .header { display: flex; align-items: center; } 
.partnerListingResult .headline .header .statusPill { background: var(--primary-colour); color: #fff; margin-left: 10px; padding: 3px 8px; } 
.partnerListingResult.unpublished .headline .header .statusPill { background: var(--error); } 
.partnerListingResult .headline .name { width: 100%; } 
.partnerListingResult .headline .header .partnerName { font-weight: 500; font-size: 2rem; } 
.partnerListingResult .headline .partnerId { font-weight: 400; } 
.partnerListingResult .headline .statusPill { background: var(--prestige); padding: 6px 10px; font-size: 1.2rem; } 
.partnerListingResult .extraDetails { width: 100%; margin-top: 8px; display: flex; flex-direction: column; color: #888; font-size: 1.6rem; } 
.partnerListingResult .extraDetails .location { width: 65%; display: flex; } 
.partnerListingResult .extraDetails .location > div { margin-right: 15px; text-transform: uppercase; font-size: 1.2rem; font-weight: 500; } 
.partnerListingResult .extraDetails .location .county { } 
.partnerListingResult .extraDetails .location .country { } 
.partnerListingResult .actions { width: 30%; } 
 
@media screen and (max-width: 1024px){
 .partnerListingResult { flex-direction: row; align-items: center; } 
 .partnerListingResult .headline .name { width: 100%; } 
 .partnerListingResult .extraDetails { flex-direction: row; align-items: center; } 
 .partnerListingResult .extraDetails .meta { width: 40%; } 
.partnerListingResult .extraDetails .location { width: 45%; } 
 }