.bookingRequestListing { display: flex; flex-direction: row; padding: 6px 0 } 
.bookingRequestListing .summaryContainer { width: 70px; margin-right: 16px; } 
.bookingRequestListing .summaryContainer .taxonomyIcon { width: 100%; } 
.bookingRequestListing .summaryContainer .taxonomyIcon svg { width: 48%; height: auto; margin: 0 auto; display: block; margin-bottom: 4px; margin-top: 7px; } 
.bookingRequestListing .summaryContainer .activeDisplay { text-align: center; } 
.bookingRequestListing .summaryContainer .activeDisplay .pending,
.bookingRequestListing .summaryContainer .activeDisplay .cancelled,
.bookingRequestListing .summaryContainer .activeDisplay .confirmed { padding: 3px 6px; margin: 0 auto; font-size: 1rem; color: #fff; } 
.bookingRequestListing .summaryContainer .activeDisplay .pending { background-color: var(--secondary-colour); } 
.bookingRequestListing .summaryContainer .activeDisplay .confirmed { background-color: var(--primary-colour); } 
.bookingRequestListing .summaryContainer .activeDisplay .cancelled { background-color: var(--error); } 

.bookingRequestListing .information { width: calc(65% - 86px); } 
.bookingRequestListing .information .headline { display: flex; flex-direction: row; align-items: center; width: 100%; font-size: 1.6rem; } 
.bookingRequestListing .information .headline .profile { min-width: 60px; width: 60px; min-height: 60px; height: 60px; margin-right: 16px } 
.bookingRequestListing .information .headline .profile .roundel { border: 3px solid var(--primary-colour) } 
.bookingRequestListing .information .headline .details { display: flex; flex-direction: column; } 
.bookingRequestListing .information .headline .number { font-size: 1.8rem; font-weight: 500; } 
.bookingRequestListing .information .headline .name { display: flex; } 
.bookingRequestListing .information .headline .guestsCount { font-weight: 600; margin-left: 20px; width: 60px; color: #aaa; display: flex; align-items: center; } 
.bookingRequestListing .information .headline .guestsCount svg { margin-left: 5px;  }
.bookingRequestListing .information .headline .guestsCount svg path { fill: #aaa }
.bookingRequestListing .information .tags { width: 100%; margin-top: 15px; display: flex; align-items: center; flex-direction: row; color: #888; } 
.bookingRequestListing .information .tags .tag { margin-left: 10px; font-size: 1.4rem; display: flex; align-items: center; } 
.bookingRequestListing .information .tags .tag .label { margin-right: 10px; } 
.bookingRequestListing .information .tags .tag .label { margin-right: 10px; } 
.bookingRequestListing .information .tags .tag.slotDateTime .value { width: 180px; text-align: center; } 
.bookingRequestListing .information .tags .tag .value { font-weight: 500; background-color: var(--secondary-colour); color: #fff } 
.bookingRequestListing .information .tags .tag:first-child { margin-left: 0; } 
.bookingRequestListing .right { display: flex; flex-direction: column; width: 35%; text-align: right; } 
.bookingRequestListing .bookingDateTime .label { color: #999; display: inline-block; } 
.bookingRequestListing .bookingDateTime { color: #222; font-size: 1.4rem; } 
.bookingRequestListing .actions { display: flex; align-items: center; justify-content: flex-end; position: relative; top: 20px; }