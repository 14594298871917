.appNotification { min-height: 44px; display: flex; align-items: center; margin: 10px 10px; position: relative; } 

.appNotification .decoration { position: absolute; background: #c2c2c2; width: 5px; height: 100%; border-radius: 6px ; }
.appNotification .icon { width: 22px; height: 22px; margin-right: 10px; margin-left: 14px; } 
.appNotification .icon .iconContainer { width: 22px; height: 22px; border-radius: 12px; background-color: #c2c2c2; }
.appNotification .icon svg { width: 22px; height: 22px; position: relative;} 
.appNotification .body { width: calc(100% - 42px); margin-left: 14px; padding: 10px 0; } 
.appNotification .title { font-size: 1.4rem; font-weight: 600; line-height: 2rem; margin-bottom: 5px; }
.appNotification .label { font-size: 1.4rem; font-weight: 400; } 

.appNotification.success { color: #29313d } 
.appNotification.success .icon .iconContainer,
.appNotification.success .decoration { background-color: #2da35c; }
.appNotification.success .title { color: #2da35c; }

.appNotification.warning { color: #29313d } 
.appNotification.warning .icon .iconContainer,
.appNotification.warning .decoration { background-color: #29313d; }
.appNotification.warning .title { color: #29313d }

.appNotification.information { color: #29313d } 
.appNotification.information .icon .iconContainer,
.appNotification.information .decoration { background-color: #c2c2c2; }
.appNotification.information .title { color: #c2c2c2; }

.appNotification.error { color: #29313d }
.appNotification.error .icon .iconContainer,
.appNotification.error .decoration { background-color: #ff6961; }
.appNotification.error .title { color: #ff6961; }