.arrow { display: inline-block; width: 8px; height: 8px; border-top: 2px solid #000; border-right: 2px solid #000; }
.arrow--top {
    transform: rotate(-45deg);
  }
  .arrow--right {
    transform: rotate(45deg);
  }
  .arrow--bottom {
    transform: rotate(135deg);
  }
  .arrow--left {
    transform: rotate(-135deg);
  }