.filter { }
.filter :global(.system-form) { width: 80%; margin: 0 auto; }
.filter fieldset { display: flex; flex-direction: row; }
.filter :global(.system-form .system-form__form-section) { width: 60%; }
.filter :global(.system-form .system-form__actions) { width: 40%; margin-top: 0; border-bottom: none; }
.filter :global(.system-form .form-field .form-field__field-container) { width: 100%; }
.results { display: flex; flex-direction: row; flex-wrap: wrap; min-height: 300px; }
.results .noResults { font-size: 2rem; width: 100%; text-align: center; margin: 20px 0; color: #aaa }
.results .loader { background: rgba(255,255,255,0.95); width: 100%; }

.userSelectionSectionValid .filter :global(.sys-button) { background: #e9e9e9; border-color: #29313d; color: #29313d }
.userSelectionSectionValid .filter :global(.sys-button .button-arrow) { border-color: #29313d; }