.results { display: flex; min-height: 300px; } 
.results .loader { background: rgba(255,255,255,0.95); width: 100%; } 

.list { list-style: none; display: flex; flex-direction: row; width: 100%; } 
.list > li { width: 30%; margin-left: 2%; } 
.list > li:first-child { margin-left: 0; } 
.list .membershipCardInner { padding: 20px 24px; border: 3px solid transparent; border-radius: var(--radius); display: flex; align-items: flex-end; flex-direction: column; } 
.list .membershipCardInner .details { display: flex; justify-content: space-around; border: 1px solid #ccc; border-radius: var(--radius); padding: 20px; margin-bottom: 20px; font-size: 1.8rem; width: 100%; } 
.list .membershipCardInner.selected { border-color: var(--primary-colour); } 

