.headline { display: flex; justify-content: space-between; align-items: center; font-size: 2rem; margin-bottom: 24px; padding-bottom: 14px; border-bottom: 2px solid #fff; color: #e2e2e2 } 
.headline > .membershipType { display: flex; } 
.headline .leading { display: flex; align-items: center; } 
.headline .taxonomyIcon { width: 40px; margin-right: 20px; display: flex; } 
.headline .taxonomyIcon svg { fill: #fff; width: 100%; height: auto } 
.headline .field { margin-top: 0; margin-left: 10px; } 
.headline .field:first-child { margin-left: 0; } 
.headline .field .value { font-size: 1.4rem } 
.membershipNumberValue { font-weight: 500; color: #fff; letter-spacing: 0.2rem; } 

.headline .awaitingActivation,
.headline .locked { padding: 5px 8px; margin-left: 20px; font-size: 1.2rem; } 
.headline .awaitingActivation { background-color: var(--warning); color: #fff; } 
.headline .locked { background-color: var(--error); } 

.field { display: flex; align-items: center; } 
.field .label { margin-right: 10px; color: #e2e2e2 } 
.field .value { padding: 12px; font-weight: 400; background: rgba(255,255,255,0.1); color: #fff; border-radius: var(--radius); } 
