.confirm { display: flex; justify-content: flex-end; flex-direction: column; max-width: 70%; margin: 0 auto; }
.summary { margin: 20px 0 40px 0; padding: 20px 24px; }
.summarySection { border-bottom: 1px solid #ccc; display: flex; flex-direction: row; align-items: center; width: 100%; font-size: 1.8rem; }
.summarySection:last-child { border-bottom: none; }
.summarySection .sectionLabel { width: 36%; margin: 20px 0; margin-right: 4%; font-weight: 500; }
.summarySection .summaryItems { width: 60%; margin: 20px 0; }
.confirm .submitButton { width: 320px; margin: 0 auto; }

.membershipSelection .primary,
.membershipSelection .guests { display: flex; flex-direction: row; }
.membershipSelection .primary { }
.membershipSelection .guests { border-top: 1px solid #ccc; margin-top: 12px; padding-top: 12px; }
.membershipSelectionTitle { font-weight: 500; width: 100px; margin-right: 12px;  }

.membershipSelection .membershipSelectionValue > div { margin-bottom: 4px; }