.backLink { float: left; } 
.payment { margin: 20px auto 0; max-width: 800px; } 
.payment .paymentSummary { padding: 30px 34px; background: var(--tertiary-colour); border-radius: var(--radius); } 
.payment .paymentSummary .headline { display: flex; justify-content: space-between; align-items: center; font-size: 2rem; margin-bottom: 24px; padding-bottom: 14px; border-bottom: 2px solid var(--secondary-colour); color: var(--secondary-colour) } 
.payment .paymentSummary .headline .leading { display: flex; align-items: center; } 
.payment .paymentSummary .headline .taxonomyIcon { width: 40px; margin-right: 20px; display: flex; } 
.payment .paymentSummary .headline .taxonomyIcon svg { fill: var(--secondary-colour) } 
.payment .paymentSummary .headline > .primaryRedemption { display: flex; } 
.payment .paymentSummary .headline .field { margin-top: 0; margin-left: 10px; } 
.payment .paymentSummary .headline .field:first-child { margin-left: 0; } 
.payment .paymentSummary .headline .field .value { font-size: 1.4rem; background: var(--primary-colour) } 
.payment .paymentSummary .referenceIdValue { font-weight: 500; color: var(--secondary-colour); letter-spacing: 0.2rem; } 
.payment .paymentSummary .amount .value { padding: 16px; font-weight: 500; border-radius: var(--radius); font-size: 1.8rem; } 
.payment .paymentSummary .amount { margin-bottom: 20px; } 
.payment .paymentSummary .values .label { width: 150px; text-align: right; margin-right: 20px; } 
.payment .paymentSummary .reason { margin-top: 10px; } 
.payment .paymentSummary .dateField { display: flex; } 
.payment .paymentSummary .dates .value:first-child { margin-right: 24px; } 
.payment .paymentSummary .dates .subLabel { margin-right: 12px; } 
.payment .paymentSummary .dates .secondaryValue { background: rgba(25,25,25,0.2); } 
.payment .paymentSummary .field { display: flex; align-items: baseline; } 
.payment .paymentSummary .field.dates { display: flex; justify-content: flex-start; align-items: baseline; margin-top: 10px; } 
.payment .paymentSummary .field.dates .field { } 
.payment .paymentSummary .secondaryFields { display: flex; flex-direction: column; } 
.payment .paymentSummary .secondaryFields .secondaryField { display: flex; align-items: center; margin-bottom: 20px; } 
.payment .paymentSummary .secondaryFields .secondaryField .subLabel { width: 120px; } 
.payment .paymentSummary .value { font-weight: 400; color: #fff; } 
.payment .paymentSummary .valueHeader { padding-top: 12px; border-top: 2px solid var(--secondary-colour); font-size: 1.4rem; font-weight: 400; color: #fff; } 
.payment .paymentSummary .stats { display: flex; align-items: center; justify-content: space-around; border-top: 2px solid var(--secondary-colour); margin-top: 24px; padding-top: 16px; } 
.payment .paymentSummary .stats .field { flex-direction: column; width: 23%; text-align: center; margin-top: 0; } 
.payment .paymentSummary .stats .field .value { background: #f2f2f2; font-weight: 600; color: var(--secondary-colour); margin-bottom: 10px; width: 50px; font-size: 1.8rem; } 
.payment .paymentSummary .stats .field.paymentValue .value { width: 100px; } 
.payment .paymentSummary .stats .field .label { margin-right: 0; width: 100%; font-weight: 500; } 
.payment .paymentSummary .stats .field .value { border-radius: var(--radius); } 
.payment .paymentSummary .field.reason .value { padding: 12px; color: var(--secondary-colour); background-color: #f2f2f2; border-radius: var(--radius); width: calc(100% - 170px); min-height: 100px; } 
.payment .paymentSummary .field.credits .value { padding: 16px; font-weight: 500; border-radius: var(--radius); font-size: 1.8rem; }
.payment .statusPill { padding: 5px 8px; font-size: 1.4rem; } 

.payment .paymentSummary .statusDates { align-items: flex-start; } 

.payment .actions { border-top: 2px solid var(--secondary-colour); margin-top: 24px; padding-top: 16px; display: flex; justify-content: flex-end; } 
.payment .actions > button { margin-left: 20px; } 
.payment .actions > button:first-child { margin-left: 0; } 

.payment .paymentSummary .paymentIndividualCostHeader,
.payment .paymentSummary .paymentIndividualCost { width: 30%; text-align: center; color: #2f2f2f; } 

.editDialog { width: 60vw; } 
.editDialog :global(.system-form__actions) { display: none; }