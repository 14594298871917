.newMembership {}
.newMembership .membershipDetails { max-width: 800px; margin: 5% auto 0; padding: 40px 44px; }
.newMembership .membershipDetails .label { font-weight: 500; width: 40%; display: inline-block; }

.newMembership .membershipDetails .type { display: flex; flex-direction: row; font-weight: 600; justify-content: space-around; margin-bottom: 20px; font-size: 1.8rem; border: 1px solid #ccc; padding: 20px 24px; width: auto;  }
.newMembership .membershipDetails .type .tier {  }
.newMembership .membershipDetails .type .annual { }

.newMembership .membershipDetails .information { margin: 10px; }

.newMembership .membershipDetails .title { font-size: 2rem; margin: 0; }
.newMembership .membershipDetails .accountNumber { font-size: 1.6rem; color: #666; margin-bottom: 10px; }
.newMembership .membershipDetails .membershipNumber { font-size: 1.6rem; color: #666; margin-bottom: 10px; }


.newMembership .membershipDetails .dates {  }
.newMembership .membershipDetails .dates > div { margin-bottom: 10px; }

.newMembership .actions { max-width: 800px; margin: 30px auto; }