.filter { } 
.filter :global(.system-form) { width: 80%; margin: 0 auto; } 
.filter fieldset { display: flex; flex-direction: row; } 
.filter :global(.system-form .system-form__form-section) { width: 60%; } 
.filter :global(.system-form .system-form__actions) { width: 40%; margin-top: 0; border-bottom: none; } 
.filter :global(.system-form .form-field .form-field__field-container) { width: 100%; } 
.results { display: flex; flex-direction: row; flex-wrap: wrap; min-height: 200px; } 
.results .noResults { font-size: 2rem; width: 100%; text-align: center; margin: 20px 0; color: #aaa } 
.results .loader { background: rgba(255,255,255,0.95); width: 100%; } 

:global(.step__section__membership.step__section--valid .system-form .sys-button) { background: #e9e9e9; border-color: transparent; color: #29313d } 
:global(.step__section__membership.step__section--valid .system-form .sys-button:focus) { border-color: #29313d; } 
:global(.step__section__membership.step__section--valid .system-form .sys-button .button-arrow) { border-color: #29313d; } 
:global(.step__section__membership .step-screen__actions) { margin-top: 0!important; margin-bottom: 20px; } 

.searchComponent { width: calc(100% - 280px); } 
.membershipSelector { display: flex; } 
.selection { display: flex; flex-direction: column; width: 220px; margin-left: 60px; align-items: flex-end; } 
.selection .selectionInner { padding: 12px 20px 20px; } 
.selection .selectionTitle { font-size: 1.6rem; font-weight: 500; margin-bottom: 10px; text-align: center; width: 100%; } 
:global(.step__section__membership.step__section--invalid) .selectionTitle { color: var(--error); } 
.selection .noSelection { width: 220px; height: 108px; padding: 20px; color: #c2c2c2; border: 2px solid var(--secondary-colour); border-radius: var(--radius); } 
.selection .primary .selectionInner,
.selection .guests .selectionInner { width: 220px; height: 108px; border: 2px solid var(--secondary-colour); border-radius: var(--radius); } 
.selection .primary .selectionInner { } 
.selection .noSelection { display: flex; align-items: center; justify-content: center; } 
.selection .guests { display: flex; flex-direction: column; } 
.selection .guests .noSelection { margin-top: 10px; } 
.selection .guests .selectionInner { margin-top: 10px; } 
.selection .selectionMembershipNumber { font-weight: 600; } 
 
.selection .selectionHeader { display: flex; justify-content: space-between; } 
.selection .selectionHeader .selectionRemove { cursor: pointer; font-size: 1.2rem; color: #777; transition: ease-in-out color 0.2s; } 
.selection .selectionHeader .selectionRemove:hover { color: #222; } 
.selection .selectionPill { color: #fff; margin-bottom: 8px; } 
.selection .primary .selectionPill { background-color: var(--primary-colour); } 
.selection .guests .selectionPill { background-color: var(--secondary-colour); } 

