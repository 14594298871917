.button { background: #e9e9e9; cursor: pointer; border-radius: 5px; border: 3px solid transparent; outline: none; padding: 8px 40px 8px 16px; font-size: 1.6rem; font-weight: 500; position: relative; transition: background-color ease-in-out 0.15s, border-color ease-in-out 0.15s, opacity 0.2s ease-in-out; -webkit-box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); -moz-box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); } 

button.button { padding: 12px 48px 12px 20px; } 

.button.disabled { opacity: 0.4; cursor: default; } 

.button:global(.danger-btn) { background-color: var(--error); } 

.button:global(.primary-bg),
.button:global(.secondary-bg),
.button:global(.danger-btn) { color: #fff } 

.button:global(.primary-bg) { border-color: #2da35c; } 
.button:global(.secondary-bg) { border-color: #29313d; } 

.button .anchorText { } 
.callToAction.solid:active,
.callToAction.solid:hover .arrow { border-color: #29313d; } 

.button:focus,
.button:active { border-color: #29313d } 

.button:global(.secondary-bg):focus,
.button:global(.secondary-bg):active { border-color: #2da35c; } 

.button.noArrow { padding-right: 26px; } 
.button.skinny { padding-top: 7px; padding-bottom: 7px; } 
.button .arrow { border: solid #29313d; border-width: 0 3px 3px 0; position: absolute; right: 20px; top: 50%; margin-top: -4px; padding: 3px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); transition: border-color ease-in-out 0.2s } 

.button:global(.primary-bg) .arrow,
.button:global(.secondary-bg) .arrow { border-color: #fff; } 
.button:not(.loading):hover .arrow { animation: shim 0.4s ease-in-out; } 

.button.loading { cursor: default; }
.button.loading .arrow,
.button.loading .anchorText { opacity: 0.2; }

.button .loader { position: absolute; left: 0; top: 50%; margin-top: -21px; width: 100%; height: 100%; } 
.button .loader :global(.custom-loader__roundel) { } 
.button .loader :global(.custom-loader__circle) { border-color: rgba(255,255,255,0.4);  border-left-color: #fff; border-width: 2.5px; height: 25px; width: 25px; } 

/* todo ghost stles */

@keyframes shim { 0%,
 100% { right: 20px; } 
 50% { right: 15px; } 
 }

@media screen and (max-width: 768px){
 .button { padding: 11px 36px 12px 20px; } 
 }
 
 @media screen and (max-width: 600px){
 .button { width: 100%; padding: 14px 42px 14px 26px; } 
 }
 