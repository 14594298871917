
.system-form { } 
.system-form { } 

/* resets */ 
.system-form .form-field input,
.system-form .form-field textarea { border-radius: 5px; border: none; outline: none; background: none; width: 100%; transition: outline 0.2s ease-in-out; } 

::placeholder { color: #ccc; opacity: 1; transition: color 0.1s ease-in-out; } 
:-ms-input-placeholder { color: #ccc; transition: color 0.1s ease-in-out; } 
::-ms-input-placeholder { color: #ccc; transition: color 0.1s ease-in-out; } 

.system-form .form-field .form-field__field:hover ::placeholder,
.system-form .form-field .form-field__field :active::placeholder,
.system-form .form-field .form-field__field :focus::placeholder { color: #999; opacity: 1; } 
.system-form .form-field .form-field__field:hover :-ms-input-placeholder,
.system-form .form-field .form-field__field :active:-ms-input-placeholder,
.system-form .form-field .form-field__field :focus:-ms-input-placeholder { color: #999; } 
.system-form .form-field .form-field__field:hover ::-ms-input-placeholder,
.system-form .form-field .form-field__field :active::-ms-input-placeholder,
.system-form .form-field .form-field__field :focus::-ms-input-placeholder { color: #999; } 

/* 
 * generic 
 */ 
.system-form .form-field { margin: 20px 0; border-radius: 5px; } 
.system-form .form-field-group { width: 100%; display: flex; flex-direction: row; } 
.system-form .form-field > label,
.system-form .form-field > div.form-field__label { display: flex; width: 100%; } 
.system-form .form-field .form-field__label { padding-top: 13px; } 

.system-form .form-field .form-field__field-container { width: 50%; border-radius: 5px; } 
.system-form .form-field .form-field__label { width: 45%; margin-right: 5%; color: #777777; } 
.system-form .form-field .form-field__field { border-radius: 5px; position: relative; border: 1px solid #e9e9e9; -webkit-box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); -moz-box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); box-shadow: 0px 0px 6px -1px rgba(200,200,200,1); } 

/* outline states */
.system-form .form-field.form-field--error { } 
.system-form .form-field.form-field--error :focus { outline-color: #ff6961; } 
.system-form .form-field.form-field--valid { } 
.system-form .form-field.form-field--valid :focus { outline-color: #5cb85c; } 

/* sections */
.system-form .system-form__section-header { display: flex; justify-content: space-between; align-items: center; } 
.system-form .system-form__form-section,
.system-form .system-form__actions { border-bottom: 1px solid #e2e2e2; margin-top: 10px; padding-top: 20px; } 
.system-form .system-form__form-section:first-child { padding-top: 0; } 
.system-form .system-form__actions { justify-content: flex-end; padding-bottom: 20px; } 
.system-form .system-form__form-section.system-form__form-section--no-label { padding: 0; border: 0; margin-top: 0; } 
.system-form .system-form__form-section-label { font-weight: 500; font-size: 2rem; line-height: 3rem; } 
.system-form .system-form__form-section.system-form__collapsed { border-bottom: none; } 
.system-form .system-form__form-section.system-form__collapsed .system-form__fields { display: none; } 

/* fields */
.system-form .form-field input,
.system-form .form-field textarea { resize: none; padding: 16px 55px 16px 24px; outline: 2px solid transparent } 
.system-form .form-field input:focus,
.system-form .form-field textarea:focus { outline-color: #29313d; } 
.system-form .form-field textarea { min-height: 150px; } 

/* error messages */
.system-form .form-field .form-field__errors { width: 100%; display: table-row; } 
.system-form .form-field .form-field__error-message { display: table-cell; opacity: 0; transition: opacity 0.4s ease-in-out; } 
.system-form .form-field .form-field__error-message { width: 50%; padding: 16px 10px 12px 12px; color:#ff6961; font-weight: 500; } 
.system-form .form-field .form-field__error-message.show { opacity: 1; } 

/* validation indicators */
.form-field__validation-status .checkmark,
.form-field__validation-status .crossmark { position: absolute; top: 12px; right: 8px; width: 30px; height: 30px; } 
.checkmark.draw:after { animation-duration: 800ms; animation-timing-function: ease; animation-name: checkmark; transform: scaleX(-1) rotate(135deg); } 
.checkmark:after { opacity: 1; height: 15px; width: 4px; transform-origin: left top; border-right: 2px solid #5cb85c; border-top: 2px solid #5cb85c; content: ""; left: 6px; top: 17px; position: absolute; } 

.form-field__validation-status .crossmark { animation: grow 0.3s; } 

.form-field__validation-status .crossmark:after,
.form-field__validation-status .crossmark:before { content: ''; height: 16px; border-left: 2px solid #ff6961; position: absolute; left: 14px; top: 7px; transition: height ease-in-out 0.2s; } 
.form-field__validation-status .crossmark:after { transform: rotate(45deg); } 
.form-field__validation-status .crossmark:before { transform: rotate(-45deg); } 

/*
 * radio & radio button list, checkbox, checkbox list and toggle button
 */

 .system-form .form-field--checkbox .form-field__field,
 .system-form .form-field--radio-button-list .form-field__field,
 .system-form .form-field--checkbox-list .form-field__field { background: none; box-shadow: none; border: none; } 
 

.system-form .radio { width: 100%; } 
.system-form .form-field--radio-button-list__list,
.system-form .form-field--checkbox-list__list { padding: 15px 0 20px 0; } 
.system-form .form-field--radio-button-list .radio,
.system-form .form-field--checkbox-list .checkbox { margin-bottom: 10px; } 

.system-form .form-field--checkbox-list.two-col .form-field--checkbox-list__list { display: flex; flex-wrap: wrap; }
.system-form .form-field--checkbox-list.two-col .form-field--checkbox-list__list .checkbox { width: 50%; }

.system-form .radio label,
.system-form .checkbox label { display: table; width: 92%; margin: 0 auto; } 
.system-form .radio label .radio__label,
.system-form .checkbox label .checkbox__label { display: table-cell; width: calc(100% - 60px); text-align: left; font-size: 1.5rem; } 
.system-form .radio label .radio__control-container,
.system-form .checkbox label .checkbox__control-container { display: table-cell; vertical-align: middle; width: 60px; margin-right: 20px; } 
.system-form .radio .radio__control,
.system-form .checkbox .checkbox__control { display: inline-block; position: relative; width: 23px; height: 23px; border-radius: 12px; cursor: pointer; } 
.system-form .checkbox .checkbox__control { border-radius: 0; } 
.system-form .radio .radio__control > input,
.system-form .checkbox .checkbox__control > input { border-radius: 12px; width: 23px; height: 23px; opacity: 0; border: none; outline: none; } 
.system-form .checkbox .checkbox__control > input { border-radius: 0; } 
.system-form .radio .radio__control > input + span.radio__control-mask,
.system-form .checkbox .checkbox__control > input + span.checkbox__control-mask { transition: outline 0.2s ease-in-out; } 
.system-form .radio .radio__control > input:focus + span.radio__control-mask,
.system-form .checkbox .checkbox__control > input:focus + span.checkbox__control-mask { outline-color: #29313d; } 
.system-form .radio .radio__control .radio__control-mask,
.system-form .checkbox .checkbox__control .checkbox__control-mask { border-radius: 12px; position: absolute; outline: 2px solid transparent; border: 2px solid transparent; width: 100%; height: 100%; left: 0; top: 0; -webkit-box-shadow: 0px 0px 6px 2px rgba(200,200,200,0.5); box-shadow: 0px 0px 6px 2px rgba(200,200,200,0.5); transition: all ease-in-out 0.1s; width: 100%; padding: 8px 9.5px 8px 10px; } 
.system-form .radio .radio__control:hover .radio__control-mask,
.system-form .checkbox .checkbox__control:hover .checkbox__control-mask { -webkit-box-shadow: 0px 0px 6px 2px rgba(180,180,180,0.7); box-shadow: 0px 0px 6px 2px rgba(180,180,180,0.7); } 
.system-form .checkbox .checkbox__control .checkbox__control-mask { border-radius: 5px; } 
.system-form .radio .radio__control .radio__control-mask > span { -webkit-transition: all 0.1s ease-in-out; -moz-transition: all 0.1s ease-in-out; transition: all 0.1s ease-in-out; border: 1px solid rgba(235,235,235,1); width: 9px; height: 9px; position: absolute; left: 5px; top: 5px; border-radius: 9px; background: rgba(240,240,240,1); } 
.system-form .radio .radio__control.checked .radio__control-mask,
.system-form .checkbox .checkbox__control.checked .checkbox__control-mask { } 
.system-form .checkbox .checkbox__control.checked .checkbox__control-mask { border-color: transparent; background-color: var(--primary-colour); } 
.system-form .radio .radio__control.checked .radio__control-mask > span { background-color: var(--primary-colour); } 
.system-form .checkbox .checkbox__control .checkbox__control-mask > span { top: 0; left: 0; width: 23px; height: 23px; display: block; position: absolute; } 
.system-form .checkbox .checkbox__control .checkbox__control-mask > span.checkmark.draw:after { animation-name: checkmarkCheckbox; height: 13px; width: 4px; border-right-color: #ffffff; border-top-color: #ffffff; left: 3px; top: 11px; } 

.system-form .form-field--toggle-button .form-field__field { border: none; box-shadow: none; } 
.system-form .toggle .toggle__control { position: relative; } 
.system-form .form-field--toggle-button-list__list { display: flex; width: calc(100% - 48px); box-shadow: 0px 0px 6px -1px rgb(200 200 200) } 
.system-form .toggle { width: 100%; text-align: center; } 

.system-form .toggle .toggle__control > input { opacity: 0; position: absolute; top: 0; left: 0; width: 100%; height: 100%; } 
.system-form .toggle label .toggle__control-container .toggle__control { cursor: pointer; padding: 8%; background-color: var(--tertiary-colour); color: var(--secondary-colour); transition: all 0.1s ease-in-out; } 
.system-form .toggle label .toggle__control-container .toggle__control:hover { background-color: var(--secondary-colour); color:#fff } 
.system-form .toggle label .toggle__control-container .toggle__control.checked { background-color: var(--primary-colour); color: #fff } 
.system-form .toggle:first-child label .toggle__control-container .toggle__control { border-top-left-radius: 5px; border-bottom-left-radius: 5px; } 
.system-form .toggle:last-child label .toggle__control-container .toggle__control { border-top-right-radius: 5px; border-bottom-right-radius: 5px; } 
.system-form .toggle label .toggle__label { } 


/* select */
.system-form .form-field.form-field--dropdown { } 

.system-form .form-field.form-field--dropdown .select-field__control { border: 2px solid transparent; padding: 6px 13px 6px 13px } 
.system-form .form-field.form-field--dropdown.form-field--valid .select-field__control,
.system-form .form-field.form-field--dropdown.form-field--error .select-field__control { padding-right: 40px; } 
.system-form .form-field.form-field--dropdown .select-field__control,
.system-form .form-field.form-field--dropdown .select-field__control:hover { border-radius: 5px; } 

.system-form .form-field.form-field--dropdown .form-field__validation-status .checkmark { top: 10px; } 
.system-form .form-field.form-field--dropdown .form-field__validation-status .crossmark { top: 12px; } 
.system-form .form-field.form-field--dropdown .select-field__dropdown-indicator { padding-top: 9px; } 

.system-form .form-field.form-field--dropdown .select-field__control--is-focused,
.system-form .form-field.form-field--dropdown .select-field__control--menu-is-open { border-color: #29313d; box-shadow: none; } 
.system-form .form-field.form-field--dropdown.form-field--valid .select-field__control--is-focused,
.system-form .form-field.form-field--dropdown.form-field--valid .select-field__control--menu-is-open { border-color: #5cb85c; } 
.system-form .form-field.form-field--dropdown.form-field--error .select-field__control--is-focused,
.system-form .form-field.form-field--dropdown.form-field--error .select-field__control--menu-is-open { border-color: #ff6961; } 
.system-form .form-field.form-field--dropdown .select-field__control--is-focused .select-field__value-container,
.system-form .form-field.form-field--dropdown .select-field__control--menu-is-open .select-field__value-container { border: none; outline: none;; } 

.system-form .form-field.form-field--dropdown .select-field__multi-value { border-radius: 5px; background: var(--secondary-colour); } 
.system-form .form-field.form-field--dropdown .select-field__multi-value__label { color: #fff; font-size: 1.4rem; padding: 5px 8px 6px 13px; } 
.system-form .form-field.form-field--dropdown .select-field__multi-value__remove { transition: ease-in-out 0.2s background-color; padding: 0 8px; border-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px; } 
.system-form .form-field.form-field--dropdown .select-field__multi-value__remove:hover { background-color: #5cb85c; } 
.system-form .form-field.form-field--dropdown .select-field__multi-value__remove svg { fill: #fff; } 

.system-form .select-field__menu { } 
.system-form .select-field__menu,
.system-form .select-field__menu .select-field__menu-list { border: none; border-radius: 0; padding: 0 } 
.system-form .select-field__menu .select-field__menu-list .select-field__option { border-radius: 0; transition: background-color ease-in-out 0.1s; padding: 12px 25px 12px; } 
.system-form .select-field__menu .select-field__menu-list .select-field__option--is-focused { background: #e9e9e9; } 
.system-form .select-field__menu .select-field__menu-list .select-field__option--is-selected { background: #29313d; } 

/* display */
.system-form .form-field.form-field--display { } 
.system-form .form-field.form-field--display .form-field__field { box-shadow: none; } 
.system-form .form-field.form-field--display .form-field__field .display-field { padding: 16px 24px 16px 24px } 
.system-form .form-field.form-field--display .form-field__field .display-field.display-image { } 
.system-form .form-field.form-field--display .form-field__field .display-field.display-image img { width: 100%; } 

/* file upload inputs */
.system-form .form-field.form-field--image-upload .form-field__field { float: left; padding: 20px; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__edit { position: relative; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__edit > label { display: flex; margin: 0 auto; width: 120px; height: 120px; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__preview { margin-bottom: 20px; float: left; width: 100%; height: auto; border-radius: 5px; display: flex; flex-direction: row; flex-wrap: wrap; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__preview-file { position: relative; border-radius: 5px; width: 100px; height: 100px; margin: 12px; background: #eee; overflow: hidden; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__preview img { width: 100%; height: auto; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__preview-placeholder { margin-right: 0; background: linear-gradient(180deg, rgba(244,244,244,1) 0%, rgba(245,245,245,1) 100%); width: 100%; transition: background-color ease-in-out 0.2s; border-radius: 5px; } 
.system-form .form-field.form-field--image-upload:not(.disabled) .form-field--image-upload__preview-placeholder { height: 100%; width: 100%; cursor: pointer; position: relative; } 
.system-form .form-field.form-field--image-upload .input-field { z-index: 1; opacity: 0; height: 100%; width: 100%; position: absolute; left: 0; top: 0; background: rgba(255,255,255,0.2); } 
.system-form .form-field .form-field--image-upload__preview { transition: opacity ease-in-out 0.2s; } 
.system-form .form-field.form-field--image-upload:not(.disabled) .input-field:hover + .form-field--image-upload__preview .form-field--image-upload__preview-placeholder,
.system-form .form-field.form-field--image-upload:not(.disabled) .input-field:focus + .form-field--image-upload__preview .form-field--image-upload__preview-placeholder { box-shadow: 0px 1px 3px 0px rgb(110 110 110 / 15%); background: linear-gradient(0deg, rgba(241,241,241,1) 0%, rgba(243,243,243,1) 100%) } 
.system-form .form-field.form-field--image-upload:not(.disabled) .form-field--image-upload__preview-placeholder span.cross { background: #d2d2d2; height: 40px; width: 5px; left: calc(50% - 2.5px); top: calc(50% - 22px); position: absolute; } 
.system-form .form-field.form-field--image-upload:not(.disabled) .form-field--image-upload__preview-placeholder span.cross:after { background: #d2d2d2; content: ""; height: 6px; left: calc(50% - 20px); position: absolute; top: calc(50% - 3px); width: 40px; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__preview-placeholder:hover { background: #f2f2f2; } 
.system-form .form-field.form-field--image-upload .form-field--image-upload__preview-placeholder:hover span.cross,
.system-form .form-field.form-field--image-upload .form-field--image-upload__preview-placeholder:hover span.cross:after { background: #b2b2b2; } 

/* actions */
.system-form__actions { display: flex; } 
.system-form__actions__edit-cancel { display: inline-block; } 

/* server errors */
.system-form__server-response-data { padding: 20px; border: 2px solid #ff6961; margin: 20px 26px; border-left: 7px solid #ff6961; border-radius: 7px; } 
.system-form__server-response-data-list { } 
.system-form__server-response-data-list > .system-form__server-response-data-list-item { color: #ff6961; margin-top: 10px; font-weight: 500; } 
.system-form__server-response-data-list > .system-form__server-response-data-list-item:first-child { margin-top: 0; } 

 @keyframes grow { 
 from { transform: scale(0); } 
 to { transform: scale(1); } 
 }

 @keyframes checkmark { 
 0% { height: 0; width: 0; opacity: 1; } 
 20% { height: 0; width: 4px; opacity: 1; } 
 40% { height: 15px; width: 4px; opacity: 1; } 
 100% { height: 15px; width: 4px; opacity: 1; } 
 }

 @keyframes checkmarkCheckbox { 
 0% { height: 0; width: 0; opacity: 1; } 
 20% { height: 0; width: 4px; opacity: 1; } 
 40% { height: 13px; width: 4px; opacity: 1; } 
 100% { height: 13px; width: 4px; opacity: 1; } 
 }

 @media screen and (max-width: 1200px){
 .system-form .form-field > label { display: block; } 
 .system-form .form-field .form-field__field-container { width: 100%; margin-top: 10px; } 
.system-form .form-field .form-field__label { width: 100%; margin-right: 0; } 
 }