.inlineLoader {  }
.inlineLoader .dot { position: relative; width: 7px; height: 7px; border-radius: 5px; background-color: #2da35c; color: #2da35c; animation: dotFlashing 0.6s infinite linear alternate; animation-delay: .2s; } 
.inlineLoader .dot::before, 
.inlineLoader .dot::after { content: ''; display: inline-block; position: absolute; top: 0; }  
.inlineLoader .dot::before { left: -10px; width: 7px; height: 7px; border-radius: 5px; background-color: #2da35c; color: #2da35c; animation: dotFlashing 0.6s infinite alternate; animation-delay: 0s; } 
.inlineLoader .dot::after { left: 10px; width: 7px; height: 7px; border-radius: 5px; background-color: #2da35c; color: #2da35c; animation: dotFlashing 0.6s infinite alternate; animation-delay: .4s; } 
 
 @keyframes dotFlashing { 
 0% { background-color: #2da35c; } 
 50%,
 100% { background-color: rgba(255,255,255,0.4); } 
 }