.item { margin-top: 20px; width: 100%;} 
.item > .inner { border: 3px solid transparent; border-radius: var(--radius); padding: 20px 24px; display: flex; flex-direction: column; } 
.item.selected > .inner { border-color: var(--primary-colour); } 
.item .membershipNumber { display: flex; flex-direction: row; align-items: center; justify-content: space-between; }
.item .membershipNumber .numberValue { font-size: 1.8rem; font-weight: 500; width: 50%; }
.item .membershipNumber .typePill { background-color: var(--secondary-colour); color: #fff; padding: 4px 10px; width: 90px; margin-left: 16px; }
.item .details {  }
.item .expiry { font-size: 1.3rem; text-align: right; display: flex; align-items: center; }
.item .select { margin-top: 10px; display: flex; flex-direction: row; justify-content: flex-end;  } 
.item .select .selectActions { display: flex; flex-direction: row; justify-content: flex-end; width: 50%; }
.item .select .selectActions > button { width: 47.5%; margin-left: 5%; }
.item .select .selectActions > button:first-child { margin-left: 0; }

@media screen and (max-width: 1300px) {
    .item { width: 48%; } 
 }