.backLink { float: left; margin-bottom: 20px; } 
.redemptionRequest { margin: 0 auto; max-width: 1400px; } 
.redemptionRequest .loader { margin-top: 10%; }
.redemptionRequest .redemptionRequestBody { margin-top: 20px; } 
.redemptionRequest .redemptionRequestCancelledBody .venue { opacity: 0.4; -moz-filter: grayscale(100%); -webkit-filter: grayscale(100%); filter: grayscale(100%); } 
.redemptionRequest .redemptionSummary { padding: 30px 34px; background: var(--tertiary-colour); border-radius: var(--radius); } 
.redemptionRequest .headline { display: flex; justify-content: space-between; align-items: center; font-size: 2rem; margin-bottom: 24px; padding-bottom: 14px; border-bottom: 2px solid var(--secondary-colour); color: var(--secondary-colour) } 
.redemptionRequest .headline .leading { display: flex; align-items: center; } 
.redemptionRequest .headline .taxonomyIcon { width: 40px; margin-right: 20px; display: flex; } 
.redemptionRequest .headline .taxonomyIcon svg { fill: var(--secondary-colour) } 
.redemptionRequest .headline .cancelled,
.redemptionRequest .headline .confirmed { padding: 12px; color: #fff; font-size: 1.6rem; } 
.redemptionRequest .headline .cancelled { background-color: var(--error); } 
.redemptionRequest .headline .confirmed { background-color: var(--primary-colour); }
.redemptionRequest .referenceIdValue { font-weight: 500; color: var(--secondary-colour); letter-spacing: 0.2rem; } 
.redemptionRequest .venue .label { width: 150px; text-align: right; margin-right: 20px; } 
.redemptionRequest .partnerDetails { margin: 14px; display: flex; text-decoration: none; border-bottom: 2px solid #999; }
.redemptionRequest .partnerDetails { width: calc(100% - 150px); }
.redemptionRequest .dates { display: flex; justify-content: flex-start; margin-top: 10px; } 
.redemptionRequest .dateField { display: flex; } 
.redemptionRequest .dates .value:first-child { margin-right: 24px; } 
.redemptionRequest .dates .subLabel { margin-right: 12px; } 
.redemptionRequest .dates .secondaryValue { background: rgba(25,25,25,0.2); } 
.redemptionRequest .members.field { margin-top: 10px; align-items: start; } 
.redemptionRequest .members .label { top: 90px; position: relative; } 
.redemptionRequest .members .value { background: none; width: calc(100% - 150px); } 
.redemptionRequest .members .value .statsValue { font-size: 1.6rem; text-align: center; } 
.redemptionRequest .profileInfo { width: 60%; display: flex; align-items: center; } 
.redemptionRequest .membershipCreditsRemaining { width: 40%; display: flex; align-items: center; justify-content: center; font-weight: 500; } 
.redemptionRequest .field { display: flex; align-items: center; } 
.redemptionRequest .value { padding: 12px; font-weight: 400; color: #fff; } 

.redemptionRequest .creditsAndFeesValues { width: calc(100% - 150px); padding: 12px; margin-top: 10px; } 
.redemptionRequest .stats { width: 100%; border-top: 2px solid #999; display: flex; padding: 16px 10px 0; align-items: center; } 
.redemptionRequest .stats .field { flex-direction: column; text-align: center; margin-top: 0; } 
.redemptionRequest .stats .field .value { margin-top: 10px; } 
.redemptionRequest .stats .field.redemptionValue { width: 60%; } 
.redemptionRequest .stats .field.redemptionValue .value.statsValue { min-width: 100px; } 
.redemptionRequest .stats .field.creditsValue { width: 40%; } 
.redemptionRequest .stats .field .label { margin-right: 0; text-align: center; width: 100%; font-weight: 500; } 

.redemptionRequest .statsValue { background: #f2f2f2; font-weight: 600; color: var(--secondary-colour); padding: 12px; width: 50px; font-size: 1.8rem; border-radius: var(--radius); } 

.redemptionRequest .statsValue.error { background-color: var(--error); color: #fff } 

.redemptionRequest .userLink { margin-top: 12px; } 
.redemptionRequest .userLink:first-child { margin-top: 0; } 
.redemptionRequest .userLink a { padding: 10px; display: flex; text-decoration: none; color: var(--secondary-colour); background-color: #d6d6d6; border-radius: var(--radius); transition: background ease-in-out 0.2s; } 
.redemptionRequest .userLink a:hover { background-color: #c9c9c9; } 
.redemptionRequest .userLink .userRoundel { width: 60px; height: 60px; min-width: 60px; margin-right: 10px; border: 3px solid transparent } 
.redemptionRequest .primaryMember .userRoundel { border-color: var(--primary-colour) } 
.redemptionRequest .userLink .memberUserName { font-size: 1.6rem; font-weight: 500; } 
.redemptionRequest .userLink .membershipNumber { font-size: 1.3rem; } 

.membersListHeading { color: #222; display: flex; width: 100%; margin-bottom: 10px; padding-top: 16px; border-top: 2px solid #999 } 
.membersListHeading .listHeadingName { width: 60%; } 
.membersListHeading .listHeadingCredits { width: 40%; text-align: center; font-weight: 500; } 

.processButtons { display: flex; flex-direction: column; } 
.processButtons > button { margin-top: 18px } 
