.userListingResult { display: flex; flex-direction: row; align-items: center; } 

.userListingResult .profileContainer { width: 70px; margin-right: 15px; display: flex; align-items: center; flex-direction: column; } 
.userListingResult .information { width: calc(100% - 85px); margin-top: 10px; } 

.userListingResult .profileContainer .profile { width: 50px; height: 50px; } 
.userListingResult .profileContainer .role { margin-top: 8px; text-align: center; } 
.userListingResult .headline { width: 100%; display: flex; flex-direction: row; align-items: center; } 
.userListingResult .headline .name { font-weight: 500; width: 33%; font-size: 1.8rem; } 
.userListingResult .headline .name .accountNumber { font-size: 1.2rem; } 
.userListingResult .headline .email { width: 65%; font-size: 1.6rem; } 

.userListingResult .extraDetails { width: 100%; margin-top: 8px; display: flex; align-items: center; flex-direction: row; color: #888; font-size: 1.6rem; } 
.userListingResult .extraDetails .meta { width: 33%; font-size: 1.4rem; } 
.userListingResult .extraDetails .meta span.metaLabel {  }
.userListingResult .extraDetails .meta span.metaValue { font-weight: 500; }
.userListingResult .extraDetails .location { width: 65%; display: flex; } 
.userListingResult .extraDetails .location > div { margin-right: 15px; text-transform: uppercase; font-size: 1.2rem; font-weight: 500; } 
.userListingResult .extraDetails .location .county { } 
.userListingResult .extraDetails .location .country { } 

.userListingResult .extraDetails .actions { width: 20%; } 
 