.linkButton { border-radius: 4px; border: 2px solid var(--secondary-colour); padding: 15px 20px; display: flex; float: right; align-items: center; justify-content: flex-end; cursor: pointer; text-decoration: none; transition: border-color ease-in-out 0.3s; } 
.linkButton.solid { border: none; background: #ccc; }
.linkButton .label { flex: 1; font-weight: 500; margin-right: 10px; font-size: 1.6rem; color: var(--secondary-colour); transition: color ease-in-out 0.4s; } 
.linkButton .label.arrowleftLabel { margin-left: 10px; margin-right: 0; } 
.linkButton.solid.primary { background-color: var(--primary-colour); }
.linkButton.solid.primary .label { color: #fff; }
.linkButton.solid.primary :global(.arrow) { border-color: #fff; }
.linkButton.solid.secondary { background-color: var(--secondary-colour); }
.linkButton.solid.secondary .label { color: #fff; }
.linkButton.solid.secondary :global(.arrow) { border-color: #fff; }
.linkButton :global(.arrow) { border-color: var(--secondary-colour); transition: border-color ease-in-out 0.25s; } 
.linkButton:focus,
.linkButton:hover { border-color: var(--primary-colour); outline-color:var(--primary-colour); } 
.linkButton:focus .label,
.linkButton:hover .label { color: var(--primary-colour) } 
.linkButton:focus :global(.arrow),
.linkButton:hover :global(.arrow) { border-color: var(--primary-colour); } 

/* modes */
.linkButton.small { padding: 8px 10px; } 
.linkButton.small .label { font-size: 1.4rem; } 