.membership .membershipSummary { max-width: 800px; margin: 20px auto 0; padding: 30px 34px; background: var(--secondary-colour); border-radius: var(--radius); } 
.membership .membershipSummary .loader { margin-top: 10%; }
/* .membership .membershipSummary .headline { display: flex; justify-content: space-between; align-items: center; font-size: 2rem; margin-bottom: 24px; padding-bottom: 14px; border-bottom: 2px solid #fff; color: #e2e2e2 } 
.membership .membershipSummary .headline > .membershipType { display: flex; } 
.membership .membershipSummary .headline .leading { display: flex; align-items: center; }
.membership .membershipSummary .headline .taxonomyIcon { width: 40px; margin-right: 20px; display: flex; }
.membership .membershipSummary .headline .taxonomyIcon svg { fill: #fff; width: 100%; height: auto }
.membership .membershipSummary .headline .field { margin-top: 0; margin-left: 10px; } 
.membership .membershipSummary .headline .field:first-child { margin-left: 0; } 
.membership .membershipSummary .headline .field .value { font-size: 1.4rem }  */
.membership .membershipSummary .membershipNumberValue { font-weight: 500; color: #fff; letter-spacing: 0.2rem; } 
.membership .membershipSummary .validityDates { display: flex; justify-content: flex-start; }
.membership .membershipSummary .validityDates .field { }
.membership .membershipSummary .validityDates .field .label { width: 100px;  text-align: right; }
.membership .membershipSummary .field { display: flex; align-items: center; } 
.membership .membershipSummary .field .label { margin-right: 10px;  color: #e2e2e2 } 
.membership .membershipSummary .field .value { padding: 12px; font-weight: 400; background: rgba(255,255,255,0.1); color: #fff; border-radius: var(--radius); } 
.membership .membershipSummary .expired { background: var(--error); border-radius: 23px; padding: 7px 9px; font-size: 1.3rem; margin-left: 5px } 

.membership .membershipSummary .stats { display: flex; align-items: center; justify-content: space-around; border-bottom: 2px solid #fff; margin-bottom: 24px; padding-bottom: 16px; } 
.membership .membershipSummary .stats .field { flex-direction: column; width: 23%; text-align: center; margin-top: 0; } 
.membership .membershipSummary .stats .field .value { background: #f2f2f2; font-weight: 600; color: var(--secondary-colour); margin-bottom: 10px; width: 50px; font-size: 1.8rem; } 
.membership .membershipSummary .stats .field.totalSaving .value,
.membership .membershipSummary .stats .field.totalCost .value { width: 100px; }
.membership .membershipSummary .stats .field .label { margin-right: 0; width: 100%; font-weight: 500; } 

.membership .membershipSummary .stats .credits { display: flex; align-items: center; width: 70%; border-radius: var(--radius); padding: 24px 12px 20px 24px; background-color: #454545; } 
.membership .membershipSummary .stats .credits .subSectionTitle { color: #fff; width: 15%; margin-bottom: 20px; font-weight: 500; }
.membership .membershipSummary .stats .credits .subSectionBody { display: flex; width: 85%; justify-content: space-evenly; }