.panel { padding: 20px 24px; max-width: 800px; margin: 0 auto; } 
.panel h2 { color: #888; font-size: 2rem; font-weight: 500; } 
.loader { width: 80%; margin: 0 auto; } 
.loader :global(.custom-loader__roundel > div) { width: 40px; height: 40px; } 
.loader :global(.custom-loader__body .custom-loader__message) { font-size: 1.6rem; margin-top: 20px } 

.heading { display: flex; justify-content: space-between; align-items: center; } 
.heading .new {  } 

.redemptions { list-style: none; padding: 0; } 
.redemptions .redemption { margin-top: 10px; } 
.redemptions .redemption a { display: block; padding: 20px 14px; background: #f9f9f9; border-radius: var(--radius); transition: background ease-in-out 0.2s; cursor: pointer; text-decoration: none; color: var(--secondary-colour) } 
.redemptions .redemption:hover a { background: #f2f2f2; } 
.redemptions .redemption.active a { background: var(--primary-colour) } 
.redemptions .redemption.active a :global(.sys-checkmark:after) { border-color: #fff; } 
.redemptions .redemption.active a { color: #fff } 

.redemptions .redemptionEntity { display: flex; align-items: center; }
.redemptions .redemptionEntity > div { text-align: left; } 
.redemptions .redemptionEntity > .slotDateTime { width: 15%; } 
.redemptions .redemptionEntity > .partnerName { width: 35% } 
.redemptions .redemptionEntity > .referenceId { width: 40%; } 
.redemptions .redemptionEntity > .credits { width: 10%; text-align: center; }
.redemptions .redemptionEntity .taxonomyIcon { margin-right: 10px; width: 18px; display: flex; align-items: center; }
.redemptions .redemptionEntity .taxonomyIcon svg { fill: var(--secondary-colour) } 

.redemptions .redemption:first-child { margin-top: 0; } 
.redemptions .redemption .redemptionEntity { display: flex; justify-content: space-around; } 
.redemptions .redemption .status { position: relative; width: 40px; margin-right: 10px; } 
.redemptions .redemption .status .crossmark:before,
.redemptions .redemption .status .crossmark:after { border-color: #888; border-width: 3px; } 
.redemptions .redemption .arrow { opacity: 0; transition: opacity 0.2s ease-in-out; width: 10px; } 
.redemptions .redemption:hover .arrow { opacity: 1; } 