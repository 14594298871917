.info { display: flex;  } 
.profileAvatarContainer { width: 50px; height: 50px; margin-right: 20px; } 
.info .profileSummaryDetails { margin-top: 10px; width: calc(100% - 70px); } 
.usernameTitle { font-size: 2rem; } 
.email { font-size: 1.6rem; margin: 4px 0 10px 0; } 
.accountNumber { font-size: 1.4rem; color: #222; font-weight: 600; }
.accountSince { font-size: 1.4rem; }

.loader { width: 80%; margin: 0 auto; top: 20%; position: relative; }
.loader :global(.custom-loader__roundel > div) { width: 40px; height: 40px; margin-top: 16px; }
.loader :global(.custom-loader__body .custom-loader__message) { font-size: 1.6rem; margin-top: 10px; margin-bottom: 0; }