.item { margin-top: 20px; width: 100%; height: 180px; } 
.item > .inner { border: 3px solid transparent; border-radius: var(--radius); padding: 20px 24px; display: flex; flex-direction: column; } 
.item.selected > .inner { border-color: var(--primary-colour); } 
.item .summary { display: flex; }
.item .summary .basic { width: calc(100% - 200px); } 
.item .summary .partnerId { width: 200px; display: flex; justify-content: flex-end; align-items: flex-start; }
.item .summary .partnerId .partnerIdPill { background-color: var(--secondary-colour); color: #fff; padding: 4px 10px; margin-left: 16px; }
.item .summary .name { font-size: 1.8rem; font-weight: 500; } 
.item .location { display: flex; flex-direction: row; margin-top: 10px; } 
.item .location .county { margin-right: 10px; }
.item .location .county,
.item .location .country { font-size: 1.4rem; padding: 4px 8px; font-size: 1.2rem; }
.item .footer { display: flex; justify-content: flex-end; align-items: center; }
.item .select { display: flex; justify-content: flex-end; } 

@media screen and (max-width: 1300px) {
    .item { width: 48%; } 
 }