.backLink { float: left;  }
.redemption { margin: 20px auto 0; max-width: 800px; }
.redemption .redemptionSummary { padding: 30px 34px; background: var(--tertiary-colour); border-radius: var(--radius); } 
.redemption .redemptionSummary .headline { display: flex; align-items: center; font-size: 2rem; margin-bottom: 24px; padding-bottom: 14px; border-bottom: 2px solid var(--secondary-colour); color: var(--secondary-colour) } 
.redemption .redemptionSummary .headline .leading { display: flex; align-items: center;  }
.redemption .redemptionSummary .headline .taxonomyIcon { width: 40px; margin-right: 20px; display: flex; }
.redemption .redemptionSummary .headline .taxonomyIcon svg { fill: var(--secondary-colour) }
.redemption .redemptionSummary .headline > .primaryRedemption { display: flex; } 
.redemption .redemptionSummary .headline .field { margin-top: 0; margin-left: 10px; } 
.redemption .redemptionSummary .headline .field:first-child { margin-left: 0; } 
.redemption .redemptionSummary .headline .field .value { font-size: 1.4rem; background: var(--primary-colour) } 
.redemption .redemptionSummary .referenceIdValue { font-weight: 500; color: var(--secondary-colour); letter-spacing: 0.2rem; } 
.redemption .redemptionSummary .venue .label { width: 150px; text-align: right; margin-right: 20px; }
.redemption .redemptionSummary .dates { display: flex; justify-content: flex-start; margin-top: 10px; }
.redemption .redemptionSummary .dateField { display: flex; }
.redemption .redemptionSummary .dates .value:first-child { margin-right: 24px; }
.redemption .redemptionSummary .dates .subLabel { margin-right: 12px; }
.redemption .redemptionSummary .dates .secondaryValue { background: rgba(25,25,25,0.2); }
.redemption .redemptionSummary .membersContainer { margin-top: 20px; }
.redemption .redemptionSummary .members.field {  align-items: start; }
.redemption .redemptionSummary .members .label { top: 60px; position: relative; }
.redemption .redemptionSummary .members .value,
.redemption .redemptionSummary .members .valueHeader { margin-right: 10px; background: none; width: calc(100% - 170px); }
.redemption .redemptionSummary .members .value { padding: 12px; }
.redemption .redemptionSummary .field { display: flex; align-items: center; } 
.redemption .redemptionSummary .value {  font-weight: 400; color: #fff; } 
.redemption .redemptionSummary .valueHeader { padding-top: 12px; border-top: 2px solid var(--secondary-colour); font-size: 1.4rem; font-weight: 400; color: #fff;  } 
.redemption .redemptionSummary .stats { display: flex; align-items: center; justify-content: space-around; border-top: 2px solid var(--secondary-colour); margin-top: 24px; padding-top: 16px; } 
.redemption .redemptionSummary .stats .field { flex-direction: column; width: 23%; text-align: center; margin-top: 0; } 
.redemption .redemptionSummary .stats .field .value { padding: 12px; background: #f2f2f2; font-weight: 600; color: var(--secondary-colour); margin-bottom: 10px; width: 50px; font-size: 1.8rem; } 
.redemption .redemptionSummary .stats .field.redemptionValue .value { width: 100px; } 
.redemption .redemptionSummary .stats .field .label { margin-right: 0; width: 100%; font-weight: 500; }
.redemption .redemptionSummary .stats .field .value { border-radius: var(--radius); }
.redemption .redemptionSummary .userLink { margin-top: 12px; display: flex; align-items: center; width: 100%; }
.redemption .redemptionSummary .userLink:first-child { margin-top: 0; }
.redemption .redemptionSummary .userLink a { display: flex;  width: 70%; align-items: center; text-decoration: none; color: var(--secondary-colour); }
.redemption .redemptionSummary .userLink .userRoundel { width: 60px; height: 60px; margin-right: 10px; border: 3px solid transparent }
.redemption .redemptionSummary .primaryMember .userRoundel { border-color: var(--primary-colour) }
.redemption .redemptionSummary .userLink .memberUserName { font-size: 1.6rem; font-weight: 500; }
.redemption .redemptionSummary .userLink .membershipNumber { font-size: 1.3rem; }

.redemption .redemptionSummary .memberListHeader { width: 100%; display: flex; }
.redemption .redemptionSummary .memberDetailsHeader { width: 70%;  }
.redemption .redemptionSummary .redemptionIndividualCostHeader,
.redemption .redemptionSummary .redemptionIndividualCost { width: 30%; text-align: center; color: #2f2f2f; }