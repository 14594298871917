.filterListing { display: flex; flex-direction: row; padding-top: 30px; width: 100%; } 

.filterListing .filter { width: 30%; margin-right: 2%; } 
.filterListing .filter.filterLoading .formContainer { filter: blur(2px) grayscale(1); } 
.filterListing .filter.filterLoading .disabledMask { position: absolute; top: 0; left: 0; width: 100%; height: 100%; } 

.filterListing .filter :global(.system-form .system-form__form-section) { margin-top: 0; } 
.filterListing .filter :global(.system-form .form-field) { margin: 10px 0; } 
.filterListing .filter :global(.system-form .system-form__form-section-label) { font-size: 1.6rem; color: #aaa; font-weight: 500; } 
.filterListing .filter :global(.system-form .form-field.form-field > label) { flex-direction: column; } 
.filterListing .filter :global(.system-form .form-field.form-field .form-field__label),
.filterListing .filter :global(.system-form .form-field.form-field .form-field__field-container) { width: 100%; } 
.filterListing .filter :global(.system-form .form-field.form-field .form-field__label) { padding-left: 24px; } 
.filterListing .filter :global(.system-form .form-field.form-field .form-field__field-container) { margin-top: 10px; } 
.filterListing .filter :global(.system-form .form-field.form-field .form-field__validation-status) { display: none; } 
.filterListing .filter :global(.system-form .form-field.form-field--dropdown.form-field--valid .select-field__control),
.filterListing .filter :global(.system-form .form-field.form-field--dropdown.form-field--error .select-field__control) { padding-right: 13px; } 
.filterListing .filter :global(.system-form__actions) { justify-content: flex-end; margin-top: 20px; } 
.filterListing .results { width: 66%; padding: 1%; max-height: 69vh; min-height: 40vh; overflow: auto; } 
.filterListing .results .loader { margin-top: 10%; position: relative; width: 100%; } 
.filterListing .results > div { margin-top: 16px; } 
.filterListing .results > div:first-child { margin-top: 0; } 

@media screen and (max-width: 1400px){
 .filterListing { flex-direction: column; } 
 .filterListing .filter { width: 100%; margin-right: 0; } 
 .filterListing .results { width: 98%; } 
 .filterListing .filter :global(.system-form) { width: 60%; margin: 0 auto; margin-top: 20px; } 
 .filterListing .filter :global(.system-form .system-form__form-section) { border-bottom: none; }
 .filterListing .filter :global(.system-form__actions) { margin: 0; border-bottom: none; padding-top: 10px }
 }